import {format, parseISO} from "date-fns";
import {IconButton} from "@mui/material";
import {InsertInvitation} from "@mui/icons-material";

import {MuiTableComponent, Translation, TranslationEN} from "../../../components/MuiTable";

export const MissingHourSummaryComponent = ({notLoading, thisLoading, openDialog, data, t, language}) => {
    const onClickOpenDialog = (index)=>{
        let missingHour = data[index]
        openDialog(missingHour)
    }

    const columns = [
        {
            name: "projectName",
            label: t("project"),
        },
        {
            name: "from",
            label: t("from"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "to",
            label:t("to"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "missingHours",
            label: t("missingHours"),
        },
        {
            name: t("registerHour"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton onClick={()=>{onClickOpenDialog(dataIndex)}}>
                            <InsertInvitation/>
                        </IconButton>
                    );
                }
            }
        },
    ]

    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '400px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
    };

    return (
        <MuiTableComponent data={data} title={t("missingHours")} options={options} columns={columns}/>
    )
}
