import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid} from "@mui/material";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {GetWeekdaysOfMonth} from "../../../utils/getWeekDaysOfMonth";
import {ErrorAlert, ErrorServer, SuccessAlert, InfoAlert} from "../../../custom/SwalCustom";
import SelectProjectRegisterHourComponent from "./selectProjectRegisterHourComponent";
import SelectTypeOfChargeComponent from "./selectTypeOfChargeComponent";
import { InputController } from "../../../components/CustomInput";

export const DialogRegisterHourAutomaticComponent = ({handleClose, open, notLoading, loading, reloadInitialMissingHour, t}) => {
    const [listProject, setListProject] = useState([])
    const [issueId, setIssueId] = useState()
    const [weekDaysOfMonth, setWeekDaysOfMonth] = useState(0)

    const schema = yup.object().shape({
        projectId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
        typeOfCharge: yup.string().typeError(t("isRequired")).required(t("isRequired")),
        hoursPerDay: yup.number().typeError(t("isRequired")).required(t("isRequired")),
    }).required();
    
    const typesOfCharge = [
        { id: 'Week', description: t("currentWeekLoad")},
        { id: 'Month', description: t("currentMonthLoad") },
    ]

    const {control, handleSubmit, reset, watch, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const projectId = watch("projectId")
    const typeOfCharge = watch("typeOfCharge")
    const hoursPerDay = watch("hoursPerDay")

    const [ staffing, setStaffing ] = useState();
    const [ projects, setProjects ] = useState();

    useEffect(() => {
        if (open){
            loading()
            const projectAssignments = JSON.parse(localStorage.getItem("project-assignments"));
            setListProject(projectAssignments === null ? [] : projectAssignments);
            setStaffing(projectAssignments === null ? [] : projectAssignments.filter(item => item.isStaffing));
            setProjects(projectAssignments === null ? [] : projectAssignments.filter(item => !item.isStaffing));
            setWeekDaysOfMonth(GetWeekdaysOfMonth())
            notLoading()
        }
    }, [open]);

    useEffect(() => {
        if (projectId > 0) {
            loading()
            CustomAxios.get(`time-entry/validate-timesheet/week-by-project/${projectId}`, Config()).then(({ data }) => {
                console.log(data)
            })
            CustomAxios.get(`projects/${projectId}/issues`, Config()).then(({ data }) => {
                setIssueId(data[0].id)
                notLoading()
            })
        }

    }, [projectId])

    
    useEffect(() => {
        if (typeOfCharge === 'Week') {
            loading()
            CustomAxios.get(`time-entry/validate-timesheet/week-by-project/${projectId}`, Config()).then(({ data }) => {
                if (data.isChargedHourInWeek) {
                    onClose()
                    reset()
                    InfoAlert(t("informationTitle"), t("hourChargedMessage"))
                }

            })
            notLoading()
        }

        if(typeOfCharge === 'Month') {
            loading()
            CustomAxios.get(`time-entry/validate-timesheet/month-by-project/${projectId}`, Config()).then(({ data }) => {
                if (data.isChargedHourInMonth) {
                    onClose()
                    reset()
                    InfoAlert(t("informationTitle"), t("hourChargedInMonthMessage"))
                }

            })
            notLoading()
        }

    }, [typeOfCharge])

    const onClose = () => {
        handleClose()
    }

    const onRegister = (value) => {
        onClose()
        loading()
        value["hourProductive"] = (typeOfCharge ===  'Week') ? hoursPerDay * 5 : hoursPerDay * weekDaysOfMonth
        value["issueId"] = issueId
        value["type"] = "Automatic"
        value["typeOfCharge"] = typeOfCharge
        value["hoursPerDay"] = hoursPerDay
        value["localDate"] = new Date().toISOString().substring(0, 10);
        CustomAxios.post("time-entry/charge-hours", value, Config()).then(() => {
            notLoading()
            SuccessAlert(t("loadHours"), t("doneSuccessfully", {a: "a"})).then(()=> reloadInitialMissingHour())
        }).catch(({response: {data}}) => {
            notLoading()
            onClose()
            ErrorAlert(t("errorTitle"), data.message).then()
        })
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <form onSubmit={handleSubmit(onRegister)}>
                <DialogTitle>{t("automaticHoursLoading")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <SelectProjectRegisterHourComponent control={control} defaultValue={[]} label={t("project")}
                                                                    name="projectId"
                                                                    listValue={listProject}
                                                                    listStaffing={staffing}
                                                                    listProjects={projects}/>
                                {errors.projectId &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.projectId.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <SelectTypeOfChargeComponent control={control} defaultValue={[]} label={t("typeOfLoad")} name="typeOfCharge" listValue={typesOfCharge}/>
                                {errors.typeOfCharge &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.typeOfCharge.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} id="hours-field">
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputController
                                    control={control}
                                    defaultValue={8}
                                    label={t("hoursPerDay")}
                                    name="hoursPerDay"
                                />
                                {errors.hours &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.hours.message}</span>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t("cancel")}</Button>
                    <Button type="submit">{t("load")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
