import {useState, useEffect} from 'react';

export const useUserData = () => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [userSetting, setUserSetting] = useState(null)
    const [permissions, setPermissions] = useState(null)

    useEffect(() => {
        let userLocal = localStorage.getItem("user")
        if (userLocal !== "" && userLocal !== null) {
            let user = JSON.parse(userLocal)
            let {id, email, name, surname, token, permissions, userSetting} = user;
            setToken(token)
            setUser({
                id,
                email,
                name,
                surname
            })
            setPermissions(permissions)
            setUserSetting(userSetting)
        }
    }, []);

    return {user, token,permissions, userSetting};
}
