const Translation = {
    body: {
        noMatch: "Lo sentimos, No se encontraron registros coincidentes",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
        next: "Página Siguiente ",
        previous: "Página Anterior",
        rowsPerPage: "Filas por pagina:",
        displayRows: "De",
    },
    toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver Columnas",
        filterTable: "Filtrar Tablas",
    },
    filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Limpiar",
    },
    viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Tabla de Columnas",
    },
    selectedRows: {
        text: "Fila(s) Seleccionadas",
        delete: "Eliminar",
        deleteAria: "Seleccionar Filas Para Eliminar",
    },
}

const TranslationEN = {
    body: {
        noMatch: "We feel it, no matching records were found",
        toolTip: "Order",
        columnHeaderTooltip: column => `sort by ${column.label}`
    },
    pagination: {
        next: "Next page ",
        previous: "Previous page",
        rowsPerPage: "Rows per page:",
        displayRows: "Of",
    },
    toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "See columns",
        filterTable: "Filter tables",
    },
    filter: {
        all: "All",
        title: "Filters",
        reset: "Clean",
    },
    viewColumns: {
        title: "Show columns",
        titleAria: "Show/hide column table",
    },
    selectedRows: {
        text: "Line (s) selected",
        delete: "Eliminate",
        deleteAria: "Select rows to delete",
    },
}

export{Translation, TranslationEN}
