import Swal from "sweetalert2";

import {CustomAxios} from "../../../../custom/axiosCustom";
import {Config} from "../../../../utils/configHeader";
import {ErrorAlert, SuccessAlert} from "../../../../custom/SwalCustom";

export const sendInvoiceFunction = async (thisLoading, notLoading, invoiceId, urlSendInvoice = "invoices/send", t) => {
    const {value: email} = await Swal.fire({
        icon: 'success',
        title: t("invoiceGeneratedSuccessfully"),
        input: 'email',
        inputLabel: t("copyEmail"),
        inputPlaceholder: t("enterCopyEmail"),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("sentInvoice"),
        cancelButtonText: t('cancel')
    })

    if (email) {
        thisLoading()
        let request = {
            ccEmail: email,
            invoiceId: invoiceId
        }
        CustomAxios.post(urlSendInvoice, request, Config()).then(() => {
            notLoading()
            SuccessAlert(t("uploadInvoice"), t("carriedOutSuccessfully")).then()
        }).catch(({response: {data}}) => {
            notLoading()
            ErrorAlert(t("errorUploadInvoice"), data.message).then()
        })
    }
}
