import {
  Typography,
  Link,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";

export const CardPage = ({
  image,
  title,
  content,
  urlFirst,
  textFirst,
  urlSecond,
  textSecond,
}) => {
  return (
    <Card sx={{ minHeight: "405px" }}>
      <CardMedia
        component="img"
        alt={title}
        height="140"
        image={image}
      />
      <CardContent sx={{ minHeight: "190px" }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign: "justify"}}>
          {content}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          target="_blank"
          rel="noreferrer"
          href={urlFirst}
          underline="none"
        >
          {textFirst}
        </Button>
        <Button
          component={Link}
          target="_blank"
          rel="noreferrer"
          href={urlSecond}
          underline="none"
        >
          {textSecond}
        </Button>
      </CardActions>
    </Card>
  );
};
