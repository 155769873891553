import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const SelectActivityComponent = ({control, name, label, rules, defaultValue, listValue}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({field}) => (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                    <Select
                        {...field}
                        label={label}
                        defaultValue={defaultValue}
                    >
                        {listValue?.map((value, index) => (
                            <MenuItem value={value.id} key={index}>
                                {value.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    )
}

SelectActivityComponent.prototype = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
    control: PropTypes.element.isRequired,
    defaultValue: PropTypes.any.isRequired

}

export default SelectActivityComponent;
