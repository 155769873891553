import {useForm} from "react-hook-form";

import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid} from "@mui/material";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {InputController} from "../../../components/CustomInput";
import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import InputDatePickerController from "../../../components/CustomInput/component/InputDatePickerController";
import React, {useState} from "react";
import InputNumberFormatController from "../../../components/CustomInput/component/inputNumberFormatController";
import UploadFile from "../../../examples/UploadFile";
import {ErrorAlert, SuccessAlert} from "../../../custom/SwalCustom";

const validationFileInitial = {
    required: false,
    type: false,
    size: false
}

const schema = yup.object().shape({
    ccEmail: yup.string().email("Debe ser un correo válido"),
    invoiceDate: yup.date().required("Es Requerido"),
    invoiceNumber: yup.string().required("Es Requerido"),
    total: yup.string().required("Es Requerido"),

}).required();

export const SendManualOutLoginComponent = ({handleClose, open, notLoading, loading, token, id}) => {
    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });
    const [file, setFile] = useState()
    const [validateFile, setValidateFile] = useState(validationFileInitial)

    const resetField = () => {
        reset({
            firstName: "",
            lastName: "",
            email: ""
        })
    }

    const validateSizeFile = (files) => {
        if (files.size < 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return false
        }
        if (files.size > 3000000) {
            setValidateFile({
                required: false,
                type: false,
                size: true
            })

            return true
        }
    }

    const validateTypeFile = (files) => {
        if (files.type === "application/pdf" || files.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            setValidateFile({
                required: false,
                type: false,
                size: false
            })

            return true
        }
        setValidateFile({
            required: false,
            type: true,
            size: false
        })
        return false
    }

    const requiredFile = () => {
        setValidateFile({
            type: false,
            size: false,
            required: true,
        })
    }

    const handleChangeFile = e => {
        if (e.target.files.length) {
            let isFileType = validateTypeFile(e.target.files[0])
            if (isFileType) {
                let isFileSize = validateSizeFile(e.target.files[0])
                if (!isFileSize) {
                    setFile(e.target.files[0])
                }
            }
            return null
        }
        requiredFile()
    };

    const onClose = () => {
        setFile(null)
        setValidateFile(validationFileInitial)
        resetField()
        handleClose()
    }

    const onRegister = (value) => {
        if (file === undefined) {
            requiredFile()
            return null;
        }

        if (!validateTypeFile(file) && validateSizeFile(file)) {
            return null;
        }

        loading()
        onClose()
        let formData = new FormData();
        formData.append("file", file);
        formData.append("currency", "USD");
        formData.append("ccEmail", value["ccEmail"]);
        formData.append("comments", value["comments"]);
        formData.append("itbmsTax", value["itbmsTax"]);
        formData.append("subtotal", value["subtotal"]);
        formData.append("total", value["total"]);
        formData.append("invoiceNumber", value["invoiceNumber"]);
        formData.append("invoiceDate", value["invoiceDate"].toISOString().substring(0, 10))

        CustomAxios.post(`invoice-confirmations/send-manual-invoice/${token}/${id}`, formData, Config()).then(() => {
            notLoading()
            SuccessAlert("Cargar de Factura", "Realizada con Exito").then()
        }).catch(({response:{data}}) => {
            notLoading()
            ErrorAlert("Error al Cargar Factura", data.message).then()
        })
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>Carga Manual de Factura</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputController name="invoiceNumber" control={control}
                                                         type="string"
                                                         defaultValue="" label="Numero de factura"/>
                                        {errors.invoiceNumber &&
                                        <span
                                            style={{fontSize: 12, color: "red"}}>{errors.invoiceNumber.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputNumberFormatController name="subtotal" control={control}
                                                                     type="string"
                                                                     defaultValue="0" label="Subtotal"/>
                                        {errors.subtotal &&
                                        <span style={{fontSize: 12, color: "red"}}>{errors.subtotal.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputNumberFormatController name="itbmsTax" control={control}
                                                                     defaultValue="0" label="ITBMS"/>
                                        {errors.itbmsTax &&
                                        <span style={{fontSize: 12, color: "red"}}>{errors.itbmsTax.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputNumberFormatController name="total" control={control}
                                                                     defaultValue="0" label="Total"/>
                                        {errors.total &&
                                        <span style={{fontSize: 12, color: "red"}}>{errors.total.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputController name="ccEmail" control={control}
                                                         type="email"
                                                         defaultValue="" label="Correo de Copia"/>
                                        {errors.ccEmail &&
                                        <span style={{fontSize: 12, color: "red"}}>{errors.ccEmail.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputDatePickerController
                                            control={control}
                                            defaultValue={new Date()}
                                            openTo='month'
                                            name="invoiceDate"
                                            label="Fecha de Factura"
                                            views={['year', 'month', 'day']}
                                        />
                                        {errors.invoiceDate &&
                                        <span style={{fontSize: 12, color: "red"}}>{errors.invoiceDate.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputController name="comments" control={control}
                                                         type="string"
                                                         defaultValue="" label="Comentario"/>
                                        {errors.comments &&
                                        <span
                                            style={{fontSize: 12, color: "red"}}>{errors.comments.message}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mb={2}>
                                        <UploadFile
                                            id="coverPage"
                                            nameInput="cover"
                                            onChangeFile={handleChangeFile}
                                            file={file}
                                            textButton="Cargar Factura"
                                            fileLoaded="Factura Cargada"
                                        />
                                        {validateFile.size && <span style={{fontSize: 12, color: "red"}}>El archivo no debe exceder los 3 MB</span>}
                                        {validateFile.type && <span style={{fontSize: 12, color: "red"}}>Solo debe ser formato (.pdf o .docx)</span>}
                                        {validateFile.required &&
                                        <span style={{fontSize: 12, color: "red"}}>El campo es requerido</span>}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button type="submit">Cargar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
