import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogSupportComponent({open, onClickClose, title, content, message, onChangeMessage, onSend}) {
    return (
        <div>
            <Dialog open={open} onClose={onClickClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Mensaje"
                        type="text"
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={8}
                        value={message}
                        onChange={onChangeMessage}
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickClose}>Cancelar</Button>
                    <Button onClick={onSend}>Enviar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
