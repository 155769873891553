import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";

import MDBox from "../../../components/MDBox";
import CardJobsComponent from "./component/cardJobsComponent";
import {Config, ConfigHeaderBlobResponse} from "../../../utils/configHeader";
import PaginationComponent from "../../../examples/Pagination";
import {SearchInputComponent} from "./component/searchInputComponent";
import {useLoading} from "../../../hooks/useLoading";
import {CustomAxios} from "../../../custom/axiosCustom";
import {MainLayout} from "../../layout/MainLayout";

const perChunk = 5

function Dashboard() {
    const {t} = useTranslation();
    const {loading, notLoading, thisLoading} = useLoading()
    const [vancancies, setVacancies] = useState([])
    const [allVacancies, setAllVacancies] = useState([])
    const [allVacancy, setallVacancy] = useState([])
    const [page, setPage] = useState(1);
    const [countPage, setCountPage] = useState(1)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const {control, watch, reset, formState: {errors}} = useForm({})
    useParams();

    const search = watch("search")

    const createPaginate = (data) => {
        let result = paginateArray(data)
        setPage(1)
        setVacancies(result[0])
        setAllVacancies(result)
        setCountPage(result.length)
        notLoading()
    }

    const onSearch = () => {
        thisLoading()
        let data = allVacancy.filter(vacancy => vacancy.name.toUpperCase().includes(search.toUpperCase()))
        createPaginate(data)
    }

    const onResetSearch = () => {
        thisLoading()
        reset({
            search: ""
        })
        createPaginate(allVacancy)
    }

    const handleChange = (event, value) => {
        thisLoading()
        setVacancies(allVacancies[value - 1])
        setPage(value);
        notLoading()
    };

    const paginateArray = (array) => {
        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
    }

    const onDownload = () => {
        if (!isButtonLoading) {
            setIsButtonLoading(true)
            CustomAxios.get(`vacancies/download`, ConfigHeaderBlobResponse())
                .then(({data, headers}) => {
                    const link = document.createElement("a");
                    link.target = "_blank";
                    link.download = t('vacancies')+'.xlsx';
                    link.href = window.URL.createObjectURL(
                        new Blob([data], {
                            type: headers["content-type"],
                        })
                    );
                    link.click();
                    setIsButtonLoading(false)
                })
        }
    }

    useEffect(() => {
        thisLoading()
        CustomAxios.get(`vacancies/all`, Config()).then(({data}) => {
            setallVacancy(data)
            createPaginate(data)
        }).catch(error => notLoading())
    }, [])

    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="start" bottom="0px">
                            <SearchInputComponent control={control} onSearch={onSearch} onResetSearch={onResetSearch} t={t}/>
                        </Box>
                    </Grid>
                    <Grid item md={2} lg={3} xl={3}/>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Box display="flex" justifyContent="end" bottom="0px">
                            <LoadingButton
                                style={{backgroundColor: "black", color: "white"}}
                                onClick={onDownload}
                                loading={isButtonLoading}
                                loadingIndicator="Loading..."
                                variant="contained"
                                fullWidth
                            >
                                {isButtonLoading ? t("downloading") : t("exportVacanciesToExcel")}
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {allVacancies.length > 0 ? (
                        <>
                            <Grid item xs={12}>
                                {vancancies.map((vacancy, index) => (
                                    <CardJobsComponent key={index} vacancy={vacancy} thisLoading={thisLoading} notLoading={notLoading}/>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" bottom="0px">
                                    <PaginationComponent page={page} count={countPage} onChangePage={handleChange}/>
                                </Box>
                            </Grid>
                        </>
                    ) : (<>
                        <Grid item xs={12}>
                            <Box height="600px" display="flex" justifyContent="center" alignItems="center">
                                <p>{t("noVacanciesAvailable")}</p>
                            </Box>
                        </Grid>
                    </>)}

                </Grid>
            </MDBox>
        </MainLayout>
    );
}

export default Dashboard;
