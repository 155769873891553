import { Chip, IconButton } from "@mui/material";
import {
  Download,
  Visibility,
  Cancel,
  Check,
  CancelScheduleSend,
  BrowserUpdated,
  CoPresent,
  QueryBuilder,
  ReceiptLong,
  PriceCheck, AssignmentTurnedIn, Close, Reply
} from "@mui/icons-material";


const subType = (value, t) => {
  switch (value) {
    case "Administrativa":
      return <Chip icon={<ReceiptLong style={{ color: 'white' }} />} label={t("administrative")} sx={{ backgroundColor: "#347ed6", color: "white" }} />
    default:
      return <Chip icon={<QueryBuilder style={{ color: 'white' }} />} label={t("hours")} sx={{ backgroundColor: "#2c387e", color: "white" }} />
  }
}


const types = (value, t) => {
  switch (value) {
    case "Personal":
      return <Chip icon={<CoPresent />} label={t("staff")} sx={{ backgroundColor: "#7EB207", color: "white" }} />
    case "Digital":
      return <Chip icon={<BrowserUpdated style={{ color: 'white' }} />} label={value}
        sx={{ backgroundColor: "#0B9BC5", color: "white" }} />
  }
}

const status = (value, t) => {
  switch (value) {
    case "Pendiente de revisión":
      return <Chip icon={<Visibility />} label={t("pendingReview")} />
    case "Reenviada":
      return <Chip icon={<Reply />} label={t("forward")} />
    case "Rechazada":
      return <Chip icon={<CancelScheduleSend sx={{ color: "white" }} />} label={t("rejected")}
        sx={{ backgroundColor: "#d47510", color: "white" }} />
    case "Aprobada":
      return <Chip icon={<Check sx={{ color: "white" }} />} label={t("accepted")}
        sx={{ backgroundColor: "#05a724", color: "white" }} />
    case "Cancelada":
      return <Chip icon={<Cancel style={{ color: 'white' }} />} label={t("cancelled")}
        sx={{ backgroundColor: "#d42d10", color: "white" }} />
    case "Pagada":
      return <Chip icon={<PriceCheck sx={{ color: "white" }} />} label={t("paid")}
        sx={{ backgroundColor: "#33cb4c", color: "white" }} />
    case "Consolidada":
      return <Chip icon={<AssignmentTurnedIn sx={{ color: "white" }} />} label={t("posted")}
        sx={{ backgroundColor: "#05a724", color: "white" }} />
  }
}

const paid = (value) => {
  switch (value) {
    case true:
      return <Chip icon={<Check />} label="SI" sx={{ backgroundColor: "#7EB207", color: "white" }} />
    case false:
      return <Chip icon={<Close sx={{ color: "white" }} />} label="NO"
        sx={{ backgroundColor: "#ffba2e", color: "white" }} />
  }
}

const consolidated = (value) => {
  switch (value) {
    case true:
      return <Chip icon={<Check />} label="SI" sx={{ backgroundColor: "#7EB207", color: "white" }} />
    case false:
      return <Chip icon={<Close sx={{ color: "white" }} />} label="NO"
        sx={{ backgroundColor: "#ffba2e", color: "white" }} />
  }
}

const statusPreInvoice = (value, t) => {
  switch (value) {
    case "CREATED":
      return <Chip label={t("created")}sx={{ backgroundColor: "#2962ff", color: "white" }} />
    case "SENT":
      return <Chip label={t("sent")} sx={{ backgroundColor: "#4bd86d", color: "white" }} />
    case "REJECTED":
      return <Chip label={t("rejected")} sx={{ backgroundColor: "#d47510", color: "white" }} />
    case "CANCELLED":
      return <Chip label={t("cancelled")} sx={{ backgroundColor: "#d42d10", color: "white" }} />
    case "REPLACED":
      return <Chip label={t("replaced")} sx={{ backgroundColor: "#f9a825", color: "white" }} />
    case "PAID":
      return <Chip label={t("paid")} sx={{ backgroundColor: "#9c27b0", color: "white" }} />
    case "POSTED":
      return <Chip label={t("posted")} sx={{ backgroundColor: "#0d47a1", color: "white" }} />
    case "ACCEPTED":
      return <Chip label={t("accepted")} sx={{ backgroundColor: "#4caf50", color: "white" }} />
  }
}

const statusForInvoice = (value, t) => {
  switch (value) {
    case "CREATED":
      return (
        <Chip
          label={t("created")}
          sx={{ backgroundColor: "#2962ff", color: "white" }}
        />
      );
    case "SENT":
      return (
        <Chip
          label={t("sent")}
          sx={{ backgroundColor: "#4bd86d", color: "white" }}
        />
      );
    case "REJECTED":
      return (
        <Chip
          label={t("rejected")}
          sx={{ backgroundColor: "#d47510", color: "white" }}
        />
      );
    case "CANCELLED":
      return (
        <Chip
          label={t("cancelled")}
          sx={{ backgroundColor: "#d42d10", color: "white" }}
        />
      );
    case "REPLACED":
      return (
        <Chip
          label={t("replaced")}
          sx={{ backgroundColor: "#f9a825", color: "white" }}
        />
      );
    case "PAID":
      return (
        <Chip
          label={t("paid")}
          sx={{ backgroundColor: "#9c27b0", color: "white" }}
        />
      );
    case "POSTED":
      return (
        <Chip
          label={t("posted")}
          sx={{ backgroundColor: "#0d47a1", color: "white" }}
        />
      );
    case "ACCEPTED":
      return (
        <Chip
          label={t("accepted")}
          sx={{ backgroundColor: "#4caf50", color: "white" }}
        />
      );
    case "Pendiente de revisión":
      return (
        <Chip
          label={t("pendingReview")} />
      );
    case "Pagada":
      return (
        <Chip
          label={t("paid")}
          sx={{ backgroundColor: "#9c27b0", color: "white" }}
        />
      );
    case "Rechazada":
      return (
        <Chip
          label={t("rejected")} sx={{ backgroundColor: "#d47510", color: "white" }} />
      );
    case "Aprobada":
      return (
        <Chip
          label={t("accepted")} sx={{ backgroundColor: "#05a724", color: "white" }} />
      );
    case "Cancelada":
      return (
        <Chip
          label={t("cancelled")} sx={{ backgroundColor: "#d42d10", color: "white" }} />
      );
    case "Reenviada":
      return (
        <Chip
          label={t("forward")} sx={{ backgroundColor: "#05a724", color: "white" }} />
      );
  }
};

export {
  subType, types, status, paid, consolidated, statusPreInvoice, statusForInvoice
}