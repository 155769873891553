import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Typography
} from "@mui/material";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {ErrorAlert, ErrorServer, SuccessAlert, InfoAlert} from "../../../custom/SwalCustom";
import SelectProjectRegisterHourComponent from "./selectProjectRegisterHourComponent";
import {InputController} from "../../../components/CustomInput";

export const RegisterHourDescriptiveComponent = ({handleClose, open, notLoading, loading, reloadInitialMissingHour, t}) => {
    const schema = yup.object().shape({
        projectId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
        hourProductive: yup.number().typeError(t("isRequired")).required(t("isRequired")).min(0).max(40, t("notBeDaysLongerThan40")),
        hourPaidLeave: yup.number().typeError(t("isRequired")).required(t("isRequired")).min(0).max(40, t("notBeDaysLongerThan40")),
        hourDisease: yup.number().typeError(t("isRequired")).required(t("isRequired")).min(0).max(40, t("notBeDaysLongerThan40")),
        hourUnpaidLeave: yup.number().typeError(t("isRequired")).required(t("isRequired")).min(0).max(40, t("notBeDaysLongerThan40")),
        total: yup.number().min(0).max(40, t("totalMustNotBeMoreThan40"))
    }).required();

    const [listProject, setListProject] = useState([])
    const [issueId, setIssueId] = useState()
    const {control, handleSubmit, setValue, reset, watch, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            total: 0
        },
        mode: "onChange"
    });

    const projectId = watch("projectId")
    const total = watch("total")
    const hourProductive = watch("hourProductive")
    const hourPaidLeave = watch("hourPaidLeave")
    const hourDisease = watch("hourDisease")
    const hourUnpaidLeave = watch("hourUnpaidLeave")

    const [ staffing, setStaffing ] = useState();
    const [ projects, setProjects ] = useState();

    useEffect(() => {
        let totalsum = parseInt(hourDisease === "" ? 0 : hourDisease) + parseInt(hourPaidLeave === "" ? 0 : hourPaidLeave) +
            parseInt(hourProductive === "" ? 0 : hourProductive) + parseInt(hourUnpaidLeave === "" ? 0 : hourUnpaidLeave)
        setValue("total", Number.isNaN(totalsum) ? 0 : totalsum)
    }, [hourProductive, hourPaidLeave, hourUnpaidLeave, hourDisease])

    useEffect(() => {
        if (open) {
            loading()
            const projectAssignments = JSON.parse(localStorage.getItem("project-assignments"));
            setListProject(projectAssignments === null ? [] : projectAssignments);
            setStaffing(projectAssignments === null ? [] : projectAssignments.filter(item => item.isStaffing));
            setProjects(projectAssignments === null ? [] : projectAssignments.filter(item => !item.isStaffing));
            notLoading()
        }

    }, [open]);

    useEffect(() => {
        if (projectId > 0) {
            loading()
            CustomAxios.get(`time-entry/validate-timesheet/week-by-project/${projectId}`, Config()).then(({ data }) => {
                if (data.isChargedHourInWeek) {
                    onClose()
                    reset()
                    InfoAlert(t("informationTitle"), t("hourChargedMessage"))
                }

            })
            CustomAxios.get(`projects/${projectId}/issues`, Config()).then(({data}) => {
                setIssueId(data[0].id)
                notLoading()
            })
        }
    }, [projectId])


    const onClose = () => {
        handleClose()
        reset()
    }

    const onRegister = (value) => {
        onClose()
        loading()
        value["issueId"] = issueId
        value["type"] = "Manual"
        value["localDate"] = new Date().toISOString().substring(0, 10);
        CustomAxios.post("time-entry/charge-hours", value, Config()).then(() => {
            notLoading()
            SuccessAlert(t("loadHours"), t("doneSuccessfully", {a: "a"})).then(() => reloadInitialMissingHour())
        }).catch(({response: {data}}) => {
            notLoading()
            onClose()
            ErrorAlert(t("errorTitle"), data.message).then()
        })
    }
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <form onSubmit={handleSubmit(onRegister)}>
                <DialogTitle>{t("descriptiveHourLoad")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <SelectProjectRegisterHourComponent control={control} defaultValue={[]} label={t("project")}
                                                                    name="projectId"
                                                                    listValue={listProject}
                                                                    listStaffing={staffing}
                                                                    listProjects={projects}/>
                                {errors.projectId &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.projectId.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <InputController
                                    control={control}
                                    defaultValue={0}
                                    label={t("hourProductive")}
                                    name="hourProductive"
                                />
                                {errors.hourProductive &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.hourProductive.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <InputController
                                    control={control}
                                    defaultValue={0}
                                    label={t("hourPaidLeave")}
                                    name="hourPaidLeave"
                                />
                                {errors.hourPaidLeave &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.hourPaidLeave.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <InputController
                                    control={control}
                                    defaultValue={0}
                                    label={t("hourDisease")}
                                    name="hourDisease"
                                />
                                {errors.hourDisease &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.hourDisease.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 1}}>
                                <InputController
                                    control={control}
                                    defaultValue={0}
                                    label={t("hourUnpaidLeave")}
                                    name="hourUnpaidLeave"
                                />
                                {errors.hourUnpaidLeave &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.hourUnpaidLeave.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" justifyContent="end"
                                 style={{marginLeft: 2, marginRight: 20}}>
                                <Typography variant="h6">{t("totalHours")}: {total}</Typography>
                                {errors.total &&
                                <span style={{fontSize: 12, color: "red"}}>{errors.total.message}</span>}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t("cancel")}</Button>
                    <Button type="submit">{t("load")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
