import {useEffect, useState} from "react";

import {
    Box,
    Container,
    Grid
} from "@mui/material";
import i18next from "i18next";

import {MainLayout} from "../layout/MainLayout";
import MDBox from "../../components/MDBox";
import Ingles from "../../assets/images/ModuloCMS/Beneficios/03_BE_ingles.png";
import {CardPage} from "./component/CardPage";
import {CustomAxios} from "../../custom/axiosCustom";
import {Config} from "../../utils/configHeader";
import {getUrlFile} from "../../utils/setting";
import {CoverComponent} from "./component/people-and-culture/component/coverComponent";
import { useLoading } from "../../hooks/useLoading";

export const AdministrationCoderland = () => {
    const {loading, notLoading, thisLoading} = useLoading()
    const [imageServer, setImageServer] = useState(null)
    const [linkInability, setLinkInability] = useState("")
    useEffect(() => {
        thisLoading()
        CustomAxios.get(`cms/people-culture`, Config()).then(({data}) => {
            let imageAdministration = (data.imageAdministration)
            let imageCover = data.imageCover.find((image) => image.fileName == '04_PP_Cover_Admin.png')
            imageAdministration.push(imageCover)
            setImageServer(imageAdministration)
            setLinkInability(data.linkInability)
        }).finally(notLoading)
    }, [])

    const getFile = (fileName) => {
        if (imageServer !== null) {
            const value = imageServer.find((image) => image.fileName == fileName);
            return getUrlFile(value.fileId, value.extension)
        }
    }
    return (
        <MainLayout loading={loading}>
            {
                imageServer && <Container>
                    <CoverComponent getFile={getFile} fileName="04_PP_Cover_Admin.png" name="Administración"/>
                    <MDBox py={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <CardPage
                                                title={i18next.t("unpaidLeaves")}
                                                image={getFile("02_AD_out.png")}
                                                content={i18next.t("unpaidLeavesMessage")}
                                                textFirst={i18next.t("obtain")}
                                                urlFirst="#/solicitudes/registro"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <CardPage
                                                title={i18next.t("medicalDisabilities")}
                                                image={getFile("01_AD_incapacidad.png")}
                                                content={i18next.t("medicalDisabilitiesMessage")}
                                                textFirst={i18next.t("obtain")}
                                                urlFirst="#/solicitudes/registro"
                                                textSecond={i18next.t("policies")}
                                                urlSecond={linkInability}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <CardPage
                                                title={i18next.t("laborCertificates")}
                                                image={getFile("04_AD_certificados_laborales.png")}
                                                content={i18next.t("laborCertificatesMessage")}
                                                textFirst={i18next.t("obtain")}
                                                urlFirst="#/solicitudes/registro"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Container>
            }
        </MainLayout>
    );
};
