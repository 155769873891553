import React from 'react'

import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";

export default function SelectIssuesComponent({ name, control, defaultValue, rules, label, listValue }) {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                    <Select
                        {...field}
                        label={label}
                        defaultValue={defaultValue}
                        id="grouped-select"
                    >
                        {listValue?.map((value, index) => (
                            <MenuItem value={value.id} key={index}>
                                {value.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    )
}

SelectIssuesComponent.prototype = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
    control: PropTypes.element.isRequired,
    defaultValue: PropTypes.any.isRequired,
    listValue: PropTypes.array.isRequired,
}
