import {useForm} from "react-hook-form";

import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid} from "@mui/material";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {InputController} from "../../../components/CustomInput";
import {CustomAxios} from "../../../custom/axiosCustom";
import {Config} from "../../../utils/configHeader";
import {ErrorAlert, SuccessAlert} from "../../../custom/SwalCustom";


const schema = yup.object().shape({
    ccEmail: yup.string().email("Debe ser un correo válido").required("Es Requerido")
}).required();

export const SendAutomaticInvoiceOutLoginComponent = ({handleClose, open, notLoading, loading, token, id}) => {
    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });

    const onClose = () => {
        handleClose()
    }
    const onRegister = (value) => {
        loading()
        onClose()
        value["token"] = token
        value["userId"] = id
        CustomAxios.post('invoice-confirmations/send', value, Config()).then(() => {
            notLoading()
            SuccessAlert("Carga de Factura", "Realizada con Exito").then()
        }).catch(({response: {data}}) => {
            notLoading()
            ErrorAlert("Error al Cargar Factura", data.message).then()
        })
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <form onSubmit={handleSubmit(onRegister)}>
                    <DialogTitle>Carga de Factura Automatica</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputController name="ccEmail" control={control}
                                                         type="email"
                                                         defaultValue="" label="Correo de Copia"/>
                                        {errors.ccEmail &&
                                        <span style={{fontSize: 12, color: "red"}}>{errors.ccEmail.message}</span>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button type="submit">Cargar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
