import { useEffect, useState } from "react";

import {
    Box,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import i18next from "i18next";

import { MainLayout } from "../layout/MainLayout";
import MDBox from "../../components/MDBox";
import { CustomAxios } from "../../custom/axiosCustom";
import { Config } from "../../utils/configHeader";
import { getUrlFile } from "../../utils/setting";
import { CoverComponent } from "./component/people-and-culture/component/coverComponent";
import { useLoading } from "../../hooks/useLoading";

export const FormationCoderland = () => {
    const { loading, notLoading, thisLoading } = useLoading()
    const [imageServer, setImageServer] = useState(null)
    const [links, setLinks] = useState([])
    useEffect(() => {
        thisLoading()
        CustomAxios.get(`cms/people-culture`, Config()).then(({ data }) => {
            let imageCover = data.imageCover.find((image) => image.fileName == '06_Cover_Form.png')
            setLinks(data.links)
            setImageServer([imageCover])
        }).finally(notLoading)
    }, [])

    const getFile = (fileName) => {
        if (imageServer !== null) {
            const value = imageServer.find((image) => image.fileName == fileName);
            return getUrlFile(value.fileId, value.extension)
        }
    }

    return (
        <MainLayout loading={loading}>
            <Container>
                {
                    imageServer && <CoverComponent getFile={getFile} fileName="06_Cover_Form.png" name="Formación" />
                }
                <MDBox py={3}>
                    <Grid item xs={12}>
                        <Container>
                            <Box sx={{ padding: "50px" }}>
                                <Typography variant="h4" align="center">
                                    {i18next.t("imputationOfHours")}
                                </Typography>
                                <Typography variant="p" align="justify">
                                    {i18next.t("imputationOfHoursMessage")}
                                    {/* <a
                                    href="https://drive.google.com/file/d/11_9Q-NuAt14nc2_U57flH127NKmf_Zz4/view?usp=share_link"
                                    target="_blank">click aquí</a> */}
                                </Typography>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src={`${links['Link_Video_Horas_Portal']}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container>
                            <Box sx={{ padding: "50px" }}>
                                <Typography variant="h4" align="center">
                                    {i18next.t("requesGeneration")}
                                </Typography>
                                <Typography variant="p" align="justify">
                                    {i18next.t("requesGenerationMessage")}
                                    {/* <a
                                    href="https://drive.google.com/file/d/11_9Q-NuAt14nc2_U57flH127NKmf_Zz4/view?usp=share_link"
                                    target="_blank">click aquí</a> */}
                                </Typography>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src={`${links['Link_Video_Solicitides']}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container>
                            <Box sx={{ padding: "50px" }}>
                                <Typography variant="h4" align="center">
                                    {i18next.t("vacancies")}
                                </Typography>
                                <Typography variant="p" align="justify">
                                    {i18next.t("vacancyMessage")}
                                    {/* <a
                                    href="https://drive.google.com/file/d/11_9Q-NuAt14nc2_U57flH127NKmf_Zz4/view?usp=share_link"
                                    target="_blank">click aquí</a> */}
                                </Typography>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src={`${links['Link_Video_Vacantes']}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container>
                            <Box sx={{ padding: "50px" }}>
                                <Typography variant="h4" align="center">
                                    {i18next.t("billing")}
                                </Typography>
                                <Typography variant="p" align="justify">
                                    {i18next.t("billingMessage")}
                                    {/* <a
                                    href="https://drive.google.com/file/d/11_9Q-NuAt14nc2_U57flH127NKmf_Zz4/view?usp=share_link"
                                    target="_blank">click aquí</a> */}
                                </Typography>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src={`${links['Link_Video_Facturas_Portal']}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container>
                            <Box sx={{ padding: "50px" }}>
                                <Typography variant="h4" align="center">
                                    {i18next.t("imputationOfHoursInRedmine")}
                                </Typography>
                                <Typography variant="p" align="justify">
                                    {i18next.t("imputationOfHoursInRedmineMessage")} <a
                                        href={`${links['Link_Instructivo_Redmine']}`}
                                        target="_blank">click aquí</a>
                                </Typography>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src={`${links['Link_Video_Horas_Redmine']}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container>
                            <Box sx={{ padding: "50px" }}>
                                <Typography variant="h4" align="center">
                                    {i18next.t("billing")}
                                </Typography>
                                <Typography variant="p" align="justify">
                                    {i18next.t("billingMessageProvider")} <a
                                        href={`${links['Link_Instructivo_Facturacion']}`}
                                        target="_blank">click aquí</a>
                                </Typography>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src={`${links['Link_Video_Facturas']}`}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Box>
                        </Container>
                    </Grid>
                </MDBox>
            </Container>
        </MainLayout>
    );
};
