import { useEffect, useState } from "react";

import { IconButton, Chip, Tooltip } from "@mui/material";
import { Download, Upload, Reply } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

import { useLoading } from "../../../../hooks/useLoading";
import { useUserProfile } from "../../../../hooks/useUserProfile";
import { MainLayout } from "../../../layout/MainLayout";
import MDBox from "../../../../components/MDBox";
import { ItemInvoiceStandartComponent } from "./itemInvoiceComponent";
import { CustomToolbarReloadComponent } from "../../../../components/MuiTable/CustomToolbarReloadComponent";
import { MuiTableComponent, Translation, TranslationEN} from "../../../../components/MuiTable";
import { CustomAxios, downloadFile } from "../../../../custom/axiosCustom";
import { Config } from "../../../../utils/configHeader";
import { ErrorAlert } from "../../../../custom/SwalCustom";
import { getFormatDate } from "../../../../utils/getFormatDate";
import { subType, types, statusForInvoice } from "../CustomChipComponent";
import { DialogChargedInvoiceAutomaticComponent } from "./dialogChargedInvoiceAutomaticComponent";
import { ResendInvoiceManualComponent } from "./ResendInvoiceManualComponent";

export const InvoiceView = () => {
  const {t, i18n: {language}} = useTranslation();
  const { loading, thisLoading, notLoading } = useLoading();
  const [invoiceList, setInvoiceList] = useState([]);
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogResend, setShowDialogResend] = useState(false)
  const [invoiceToSend, setInvoiceToSend] = useState(null)
  const [invoiceToResend, setInvoiceToResend] = useState(null)
  const { userData } = useUserProfile();

  const onOpenDialog = (invoiceId) => {
    setInvoiceToSend(invoiceId)
    setShowDialog(true)
  }
  const onCloseDialog = () => setShowDialog(false)

  const onOpenDialogResend = (invoiceId) => {
    setInvoiceToResend(invoiceId)
    setShowDialogResend(true)
  }

  const onCloseDialogResend = () => setShowDialogResend(false)

  const transformToDTO = (data) => {
    return data.map(item => ({
      id: item.id ? item.id : item.invoice.id,
      sentDate: item.invoice ? item.invoice.createdAt : item.sentDate,
      invoiceDate: item.invoiceDate,
      client: item.invoice?.detailList.map(detail => detail.client).join(", ") || item.consultantName,
      description: item.invoice?.detailList.map(detail => detail.description).join(", ") || item.comments,
      unitPrice: item.invoice?.detailList.map(detail => detail.unitPrice).join(", ") || item.total,
      month: item.month,
      year: item.year,
      discount: item.invoice?.discount || 0,
      total: item.invoice?.total || item.total,
      currency: item.currency,
      status: item.invoice?.status || item.status,
      types: item.types,
      subTypes: item.subTypes,
    }));
  };

  const getListInvoice = () => {
    CustomAxios.get(`invoice-submissions/allSubmissions`, Config())
      .then(async ({ data }) => {
        const transformedData = transformToDTO(data);
        setInvoiceList(transformedData);
      })
      .catch((_error) => {
        ErrorAlert("Error", "Al obtener el listado de facturas").then();
      });
  };

  const cancelInvoice = (invoiceId) => {
    const invoiceStatus = invoiceList.find(item => item.id === invoiceId);
    if (invoiceStatus.status !== 'CANCELLED') {
      Swal.fire({
        title: "Cancelar factura",
        text: "¿Deseas cancelar esta factura?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Cancelar",
        cancelButtonText: "Salir",
      }).then((result) => {
        if (result.isConfirmed) {
          CustomAxios.get(`invoices/cancel-invoice/${invoiceId}`, Config())
            .then(async ({ data }) => {
              Swal.fire({
                title: 'Cancelación exitosa',
                text: 'La factura se ha cancelado exitosamente',
                icon: 'success',
                confirmButtonText: 'OK'
              });
            })
            .catch((error) => {
              console.log("error => ", error);
              Swal.fire({
                title: 'Cancelar factura',
                text: 'Error al cancelar factura',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            })
            .finally((_info) => {
              getListInvoice();
            })
        }
      });
    } else {
      Swal.fire({
        title: 'Cancelar factura',
        text: 'Esta factura ya se encuentra cancelada',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const onDownload = (id) => {
    thisLoading();

    downloadFile(
      `invoices/${id}/download`,
      `${t("invoice")}_${userData.firstName}_${userData.lastName}${getFormatDate()}.pdf`
    )
      .finally(() => notLoading());
  };

  const getInvoice = (index) => {
    return invoiceList && invoiceList.length > 0 ? invoiceList.at(index) : null;
  }

  useEffect(() => {
    getListInvoice();
  }, []);

  const columns = [
    {
      name: "sentDate",
      label: t("sentInvoiceDate"),
      options: {
        customBodyRender: (value) => format(parseISO(value), 'dd/MM/yyyy'),
      },
    },
    {
      name: "invoiceDate",
      label: t("invoiceDate"),
      options: {
        customBodyRender: (value) => {
                                            return value ? format(parseISO(value), 'dd/MM/yyyy') : "";
                                     },
      },
    },
    {
      name: "client",
      label: "Cliente",
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      name: "description",
      label: "Descripción",
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      name: "unitPrice",
      label: "Precio Un.",
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      name: "month",
      label:t("month"),
    },
    {
      name: "year",
      label: t("year"),
    },
    {
      name: "discount",
      label: t("discount"),
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      name: "total",
      label: t("total"),
      options: {
        customBodyRender: (value) => value,
      },
    },
    {
      name: "currency",
      label:t("currency"),
    },
    {
      name: "status",
      label: t("status"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          return statusForInvoice(value, t)
        },
      },
    },
    {
      name: "types",
      label: t("type"),
      options: {
        customBodyRender: (value) => {
          return types(value, t)
        },
      },
    },
    {
      name: "subTypes",
      label: t("subtype"),
      options: {
        customBodyRender: (value) => {
          return subType(value, t)
        }
      }
    },
    {
      name: "resend",
      label: t("resend"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (_value, tableMeta) => {
          const data = getInvoice(tableMeta.rowIndex);
         
          if (!data || data.status !== 'REJECTED' || data.types !== "Personal") return "";

          return (
            <Chip
              icon={<Reply style={{ color: 'white' }} />}
              label={ t("resend")}
              sx={{ backgroundColor: "#4bd86d", color: "white" }}
              onClick={() => {
                onOpenDialogResend(data.id);
              }}
            />
          );
        },
      },
    },
    {
      name: "send",
      label:t("send"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const data = getInvoice(tableMeta.rowIndex);
          if (!data || data.status !== 'CREATED') return "";

          return (
            <Chip
              icon={<Upload style={{ color: 'white' }} />}
              label={t("send")}
              sx={{ backgroundColor: "#4bd86d", color: "white" }}
              onClick={() => {
                onOpenDialog(data.id);
              }}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: t("download"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const data = getInvoice(tableMeta.rowIndex);
          if (!data) return "";

          return (
            <Tooltip title={t("download")}>
              <IconButton
                onClick={() => {
                  onDownload(data.id);
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
    /*{
      name: "id",
      label: "Cancelar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = getInvoice(tableMeta.rowIndex);
          if (!data || !data.invoice) return "";

          return (
            <IconButton
              onClick={() => {
                cancelInvoice(data.invoice.id);
              }}
            >
              <Cancel />
            </IconButton>
          );
        },
      },
    },*/
  ];

  const options = {
    search: true,
    rowsPerPage: 20,
    download: false,
    print: false,
    viewColumns: true,
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    textLabels: language === 'es' ? Translation : TranslationEN,
    tableBodyHeight: "400px",
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <>
          <CustomToolbarReloadComponent
            title={t("reloadData")}
            onClickAction={getListInvoice}
          />
        </>
      );
    }
  };

  return (
    <MainLayout loading={loading}>
      <MDBox py={3}>
        <ItemInvoiceStandartComponent
          notLoading={notLoading}
          thisLoading={thisLoading}
          getListInvoice={getListInvoice}
          userData={userData}
        />
        <MuiTableComponent
          data={invoiceList}
          title={t("invoices")}
          options={options}
          columns={columns}
        />
      </MDBox>

      <DialogChargedInvoiceAutomaticComponent
        open={showDialog}
        loading={thisLoading}
        notLoading={notLoading}
        handleClose={onCloseDialog}
        userData={userData}
        invoiceId={invoiceToSend}
        invoices={invoiceList}
        handleInvoices={setInvoiceList}
      />
      <ResendInvoiceManualComponent
        open={showDialogResend}
        loading={thisLoading}
        notLoading={notLoading}
        handleClose={onCloseDialogResend}
        title="Reenviar Factura"
        invoiceToResend={invoiceToResend}
        reloadInvoice={getListInvoice}
        t={t}
      />
    </MainLayout>
  );
};
