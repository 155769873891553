import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Controller} from "react-hook-form";
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import parseISO from "date-fns/parseISO";

import {InputController} from "../../../../components/CustomInput";
import InputDatePickerController from "../../../../components/CustomInput/component/InputDatePickerController";
import {SelectMultiple} from "../../../../examples/SelectMultiple";
import {Config} from "../../../../utils/configHeader";
import {CustomAxios} from "../../../../custom/axiosCustom";
import MDButton from "../../../../components/MDButton";
import Swal from "sweetalert2";

const regexText = /^([a-zA-Z ]+)$/


export const UpdateProfileComponent = ({user, thisLoading, notLoading, setUpdate, t}) => {
    const schema = yup.object().shape({
        firstName: yup.string().required(t("isRequired")).max(30, t("lengthMustNotBeMoreThan30Characters")).min(2, t("lengthMustNotBeGreaterThan2Characters")).matches(regexText, t("itMustContainTextOnly")),
        lastName: yup.string().required(t("isRequired")).max(30, t("lengthMustNotBeMoreThan30Characters")).min(2, t("lengthMustNotBeGreaterThan2Characters")).matches(regexText, t("itMustContainTextOnly")),
        email: yup.string().email("Debe ser un correo válido").required(t("isRequired")),
        companyEmail: yup.string().email("Debe ser un correo válido").required(t("isRequired")),
        dateOfBirth: yup.date().typeError(t("isRequired")).required(t("isRequired")),
        country: yup.string().required(t("isRequired")),
        nationality: yup.string().required(t("isRequired")),
    }).required();
    const {control, handleSubmit, reset, watch, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(schema),
    });
    const [validateProfesionalProfile, setValidateProfesionalProfile] = useState(false)
    const [profesionalProfileSelected, setprofesionalProfileSelected] = useState([]);
    const [profesionalProfile, setProfesionalProfile] = useState([])
    const [countries, setCountries] = useState([])
    const [nationalities, setNationalities] = useState([])

    const validateExtraField = () => {
        let next = false
        if (profesionalProfileSelected.length === 0) {
            setValidateProfesionalProfile(true)
            next = true
        }

        return next
    }


    useEffect(() => {
        // thisLoading()
        CustomAxios.get(`user-profile/pickup-fields-metadata`, Config()).then(({data}) => {
            data.fields.map((field) => {
                switch (field.apiName) {
                    case "Pais_Residencia":
                        setCountries(field.pickListValues)
                        break
                    case "Perfil_Profesional":
                        setProfesionalProfile(field.pickListValues)
                        break
                    case "Nacionalidad":
                        setNationalities(field.pickListValues)
                        break
                }
            })
        }).catch((response) => {

        })
    }, []);

    useEffect(() => {
        setValue("firstName", user.firstName, {shouldDirty: true, shouldValidate: true})
        setValue("lastName", user.lastName, {shouldDirty: true, shouldValidate: true})
        setValue("country", user.country, {
            shouldDirty: true,
            shouldValidate: true
        })
        setValue("nationality", user.nationality, {
            shouldDirty: true,
            shouldValidate: true
        })
        setValue("email", user.email)
        setValue("companyEmail", user.companyEmail)
        setValue("dateOfBirth", parseISO(user.dateOfBirth))
        setValue("phoneNumber", user.phoneNumber)
        setprofesionalProfileSelected(user.professionalProfile)
    }, [user]);


    const onUpdate = (value) => {
        let title = t("profileUpdate")
        let subtitle = t("doneSuccessfully", {a: "a"})
        let comfirmationSubtitle = t("doYouWantToUpdateThisRecord")
        let textButtonComfirmation = t("update")

        Swal.fire({
            title: title,
            text: comfirmationSubtitle,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: textButtonComfirmation,
            cancelButtonText: t("cancel")
        }).then((res) => {
            if (res.isConfirmed) {
                if (!validateExtraField()) {
                    thisLoading()
                    value["professionalProfile"] = profesionalProfileSelected
                    value["currentProject"] = user.currentProject
                    CustomAxios.post(`user-profile`, value, Config())
                        .then(() => {
                            notLoading()
                            Swal.fire(
                                title,
                                subtitle,
                                'success'
                            ).then(()=>{
                                setUpdate(false)
                            })
                        }).catch(() => {

                    });
                }
            }
        })
    }

    const handleChange = (event) => {
        const {target: {value, name},} = event;
        if (name === "Perfil Profesional") {
            setprofesionalProfileSelected(typeof value === 'string' ? value.split(',') : value,);
            setValidateProfesionalProfile(false)
        }
    }
    return (
        <form onSubmit={handleSubmit(onUpdate)}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputController name="firstName" control={control}
                                         type="text"
                                         defaultValue="" label={t("name")}/>
                        {errors.firstName &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.firstName.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputController name="lastName" control={control}
                                         type="text"
                                         defaultValue="" label={t("lastname")}/>
                        {errors.lastName &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.lastName.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputController name="email" control={control}
                                         type="text"
                                         defaultValue="" label={t("email")}/>
                        {errors.email &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.email.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputController name="companyEmail" control={control}
                                         type="text"
                                         defaultValue="" label={t("corporateEmail")}/>
                        {errors.companyEmail &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.companyEmail.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="nationality"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: true,
                                maxLength: 50
                            }}
                            render={({field}) =>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("nationality")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        {...field}
                                    >
                                        {nationalities.map((nationality, index) => (
                                            <MenuItem key={index}
                                                      value={nationality}>{nationality}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        />
                        {errors.nationality &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.nationality.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="country"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: true,
                                maxLength: 50
                            }}
                            render={({field}) =>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("country")}</InputLabel>
                                    <Select
                                        {...field}
                                    >
                                        {countries.map((country, index) => (
                                            <MenuItem key={index}
                                                      value={country}>{country}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        />
                        {errors.country &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.country.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputDatePickerController
                            control={control}
                            defaultValue={new Date()}
                            openTo='month'
                            name="dateOfBirth"
                            label={t("birthdate")}
                            views={['year', 'month', 'day']}
                        />
                        {errors.dateOfBirth &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.dateOfBirth.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{mt: 0.8}}>
                        <InputController name="phoneNumber" control={control}
                                         type="text"
                                         defaultValue="" label={t("phone")}/>
                        {errors.phoneNumber &&
                        <span style={{fontSize: 12, color: "red"}}>{errors.phoneNumber.message}</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{mt: 0.8}}>
                        <SelectMultiple inputName={t("professionalProfile")}
                                        values={profesionalProfile} onChange={handleChange}
                                        selectedValue={profesionalProfileSelected}/>
                        {validateProfesionalProfile &&
                        <span style={{fontSize: 12, color: "red"}}>{t("isRequired")}o</span>}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <MDButton variant="gradient" type="submit" color="info" fullWidth>
                        {t("update")}
                    </MDButton>
                </Grid>
            </Grid>
        </form>
    )
}
