import { forwardRef } from "react";
import PropTypes from "prop-types";

import {Chip, Typography, MenuItem} from "@mui/material";
import {Close, Email} from "@mui/icons-material";
import {Link} from "react-router-dom";
import i18next from "i18next";

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import menuItem from "../../../examples/Items/NotificationItem/styles";

const getActionLink = (notification) => {
    switch (notification.type) {
        case "CANDIDATE_APPROVED":
        case "CANDIDATE_NOT_APPROVED":
        case "CANDIDATE_HIRED":
            return "/gestion-de-vacantes/candidatos";
        case "TIME_ENTRY_DEADLINE":
        case "TIME_ENTRY_DEADLINE_REACHED":
            return "/gestion-de-horas";
        case "LICENSE_APPLICATION_ACCEPTED":
        case "LICENSE_APPLICATION_REJECTED":
            return "/solicitudes";
        case "INVOICE_REJECTED":
            return "/gestion-de-facturas/factura/ver";
    }
    return null;
}

const getActionDescription = (notification) => {
    switch (notification.type) {
        case "CANDIDATE_APPROVED":
        case "CANDIDATE_NOT_APPROVED":
        case "CANDIDATE_HIRED":
            return i18next.t("seeCandidate");
        case "TIME_ENTRY_DEADLINE":
        case "TIME_ENTRY_DEADLINE_REACHED":
            return i18next.t("goToTimeManagement");
        case "LICENSE_APPLICATION_ACCEPTED":
        case "LICENSE_APPLICATION_REJECTED":
            return i18next.t("seeMyRequests");
        case "INVOICE_REJECTED":
            return i18next.t("viewInvoices");
    }
    return null;
}

const NotificationItem = forwardRef(({ notification,closeAction, ...rest }, ref) => (
  <MenuItem {...rest} style={{cursor: "default", paddingRight: "0px"}} ref={ref} sx={(theme) => menuItem(theme)}>
    <MDBox p={3} style={{padding: "0px"}}>
        <MDBox py={0.5} display="flex" alignItems="top" lineHeight={1} width="370px">
            <div style={{marginTop: "2px"}}>
                <Email/>
            </div>
            <MDTypography variant="button" sx={{ ml: 1 }} style={{whiteSpace: "break-spaces", flexGrow: 1, marginRight: "10px", fontWeight: "bold", fontSize: "0.8rem"}}>
                {notification.title}
            </MDTypography>
            <div style={{float: "right", cursor: "pointer", paddingRight:"8px" }} onClick={closeAction}>
                <Close/>
            </div>
        </MDBox>
        <div style={{whiteSpace: "break-spaces"}}>
            <Typography paragraph style={{fontSize: "0.7rem", paddingRight:"8px", marginBottom: "10px"}} width="370px">
                {notification.message}
            </Typography>
        </div>
        {getActionLink(notification) && (
            <div style={{textAlign: "center", paddingRight:"8px" }} >
                <Link to={getActionLink(notification)}>
                        <Chip label={getActionDescription(notification)} size="small" variant="outlined" style={{cursor: "pointer"}}/>
                </Link>
            </div>
        )}

    </MDBox>
  </MenuItem>
));

NotificationItem.propTypes = {
    closeAction: PropTypes.func.isRequired
};

export default NotificationItem;
