import React from "react";

import { Box, FormControl, Grid } from "@mui/material";

import { InputController, InputNumberFormatController, InputDatePickerController } from "../../../../components/CustomInput";
import UploadFile from "../../../../examples/UploadFile";

export const ChargedAutomaticComponent = ({ errors, control, file, handleChangeFile, validateFile, t }) => {
    return (
        <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="invoiceNumber" control={control}
                        type="number"
                        defaultValue="" label={t("invoiceNumber")} />
                    {errors.invoiceNumber &&
                        <span
                            style={{ fontSize: 12, color: "red" }}>{errors.invoiceNumber.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputNumberFormatController name="subtotal" control={control}
                        type="string"
                        defaultValue="" label={t("subTotal")} />
                    {errors.subtotal &&
                        <span style={{ fontSize: 12, color: "red" }}>{errors.subtotal.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputNumberFormatController name="itbmsTax" control={control}
                        defaultValue="" label="ITBMS" />
                    {errors.itbmsTax &&
                        <span style={{ fontSize: 12, color: "red" }}>{errors.itbmsTax.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputNumberFormatController name="total" control={control}
                        defaultValue="" label={t("total")} />
                    {errors.total &&
                        <span style={{ fontSize: 12, color: "red" }}>{errors.total.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="ccEmail" control={control}
                        type="email"
                        defaultValue="" label={t("ccEmail")} />
                    {errors.ccEmail &&
                        <span style={{ fontSize: 12, color: "red" }}>{errors.ccEmail.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputDatePickerController
                        control={control}
                        defaultValue={new Date()}
                        openTo='month'
                        name="invoiceDate"
                        label={t("invoiceDate")}
                        views={['year', 'month', 'day']}
                    />
                    {errors.invoiceDate &&
                        <span style={{ fontSize: 12, color: "red" }}>{errors.invoiceDate.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputController name="comments" control={control}
                        type="string"
                        defaultValue="" label={t("comments")} />
                    {errors.comments &&
                        <span
                            style={{ fontSize: 12, color: "red" }}>{errors.comments.message}</span>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box mb={2}>
                    <UploadFile
                        id="coverPage"
                        nameInput="cover"
                        onChangeFile={handleChangeFile}
                        file={file}
                        textButton={t("loadInvoice")}
                        fileLoaded={t("loadedInvoice")}
                    />
                    {validateFile.size &&
                        <span style={{ fontSize: 12, color: "red" }}>{t("maximumFileSizeInvoice")}</span>}
                    {validateFile.type &&
                        <span style={{ fontSize: 12, color: "red" }}>{t("allowedFormatInvoice")}</span>}
                    {validateFile.required &&
                        <span style={{ fontSize: 12, color: "red" }}>{t("isRequired")}</span>}
                </Box>
            </Grid>
        </>
    )
}
