import Swal from "sweetalert2";
import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";
import i18next from 'i18next';

import { CustomAxios } from "../../../../custom/axiosCustom";
import { Config } from "../../../../utils/configHeader";
import { sendInvoiceFunction } from "./SendInvoiceFuntion";
import { ErrorAlert, InfoAlert } from "../../../../custom/SwalCustom";
import { FormatNumberMoney } from "../../../../utils/moneyFormat";

export const generateInvoiceFunction = (
  data,
  thisLoading,
  notLoading,
  onActiveAddItemInvoiceDialog,
  urlPreInvoicePending,
  urlConfirmPreInvoice,
  urlSendInvoice
) => {
  const { details, currency, total, benefitDiscount, month, year } = data;
  notLoading();
  
    Swal.fire({
      html: `<br> <h2>${i18next.t("resume")}</h2>
    <div>${i18next.t("period")}: ${format(new Date(year, month - 1, 1), 'MMMM - yyyy', { locale: i18next.language === "es" ? es : enUS}).toUpperCase()} </div>
             ${details.map((detail) =>
        `<br>${detail.description}
             <div>${i18next.t("totalHours")}: ${detail.hours}</div>
             <div>${i18next.t("units")}: ${detail.units}</div>
             <div>${i18next.t("amount")}: ${FormatNumberMoney(currency).format(
          detail.total
        )}</div>
             `
      )}
            <br>${i18next.t("discountByBenefits")}: ${FormatNumberMoney(currency).format(-benefitDiscount)}
            <br>${i18next.t("total")}:  ${FormatNumberMoney(currency).format(total)}`,
      title: i18next.t("preInvoiceGenerateSuccessful"),
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      showDenyButton: true,
      cancelButtonColor: "#d33",
      denyButtonColor: "#049942",
      confirmButtonText: i18next.t("generateInvoice"),
      denyButtonText: i18next.t("addAdditionalConcept"),
      cancelButtonText: i18next.t("cancel"),
    }).then(({ isConfirmed, isDenied }) => {
      if (isConfirmed) {
        showConfirmationDialog(
          thisLoading,
          notLoading,
          onActiveAddItemInvoiceDialog,
          total,
          currency,
          urlPreInvoicePending,
          urlConfirmPreInvoice,
          urlSendInvoice,
          i18next.t
        );
      }
      if (isDenied) {
        onActiveAddItemInvoiceDialog();
      }
    });
  };


const showConfirmationDialog = (
  thisLoading,
  notLoading,
  onActiveAddItemInvoiceDialog,
  invoiceTotal,
  currency,
  urlPreInvoicePending,
  urlConfirmPreInvoice,
  urlSendInvoice,
  t
) => {
  Swal.fire({
    title: t("generateInvoiceQuestion"),
    html: `${t("afterSendingNotModified")}<br><br><strong>${t("totalInvoice")}: ${FormatNumberMoney(currency).format(invoiceTotal)}</strong>`,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: t("confirm"),
    cancelButtonText: "No",
  }).then(({ isConfirmed, isDenied }) => {
    if (isConfirmed) {
      thisLoading();
      CustomAxios.get(`${urlPreInvoicePending}`, Config())
        .then(({ data }) => {
          CustomAxios.get(
            `${urlConfirmPreInvoice}${data[0].id}`,
            Config()
          )
            .then((response) => {
              notLoading();
              sendInvoiceFunction(
                thisLoading,
                notLoading,
                response.data.id,
                urlSendInvoice,
                t
              ).then();
            })
            .catch(({ response: { data } }) => {
              notLoading();
              if (data.code === 400) {
                ErrorAlert(t("errorToGenerateInvoice"), data.message).then();
                return;
              }
              ErrorAlert("Error", t("errorToGenerateInvoice")).then();
            });
        })
        .catch((response) => {
          notLoading();
          if (response.data === undefined) {
            InfoAlert(
              t("generateInvoice"),
              t("noPreInvoicesAvailable")
            ).then();
            return;
          }
          if (response.data.code === 400) {
            ErrorAlert(
              t("errorToGenerateInvoice"),
              response.data.message
            ).then();
            return;
          }
          ErrorAlert("Error", t("errorToGenerateInvoice")).then();
        });
    }
    if (isDenied) {
      onActiveAddItemInvoiceDialog();
    }
  });
};
