import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { CustomAxios } from "../../../custom/axiosCustom";
import { Config } from "../../../utils/configHeader";
import { ErrorAlert, SuccessAlert } from "../../../custom/SwalCustom";
import SelectProjectRegisterHourComponent from "./selectProjectRegisterHourComponent";
import { InputController } from "../../../components/CustomInput";
import SelectActivityComponent from "./selectActivityComponent";
import InputDatePickerController from "../../../components/CustomInput/component/InputDatePickerController";
import SelectAssignedProjectComponent from "./selectAssignedProjectComponent";

import { userContainPermission } from "../../../utils/permission";
import { usePermission } from "../../../hooks/usePermission";

export const DialogChargedHourComponent = ({ handleClose, open, notLoading, loading, reloadInitialMissingHour, t }) => {
    const schema = ({ isFestive, hasAssignedProjectPermission }) => {
        let
            doNotRequireHours = isFestive(),
            minHours = doNotRequireHours ? 0 : 1,
            hoursMessage = t("minimumValueShouldBeHour", {minHours: minHours})
        ;
    
        return yup.object().shape({
            projectId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
            activityId: yup.string().typeError(t("isRequired")).required(t("isRequired")),
            spentOn: yup.date().typeError(t("isRequired")).required(t("isRequired")),
            hours: yup.number().typeError(t("isRequired")).required(t("isRequired")).min(minHours, hoursMessage),
            comment: yup.string().typeError(t("isRequired")).required(t("isRequired")),
            assignedProjectId: yup.string().when('hasAssignedProjectPermission', {
                is: true,
                then: yup.string().required(t("isRequired")),
                otherwise: yup.string().notRequired()
            }),
        }).required();
    }

    const [listProject, setListProject] = useState();
    const [activityList, setActivityList] = useState();
    const [assignedProjectList, setAssignedProject] = useState();
    const [issueId, setIssueId] = useState();
    const [hoursFieldVisible, setHoursFieldVisible] = useState(true);

    const { permissionList } = usePermission("Perfil de Imputacion");

    const existPermission = (permission) => {
        return userContainPermission(permission, permissionList);
    };

    const { control, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema({
            isFestive: () => !hoursFieldVisible,
            hasAssignedProjectPermission: existPermission("Estructura") || existPermission("Estructura Operativa")
        })),
    });

    const projectId = watch("projectId");
    const activityId = watch("activityId");
    const assignedProjectId = watch("assignedProjectId");

    const [staffing, setStaffing] = useState();
    const [projects, setProjects] = useState();

    useEffect(() => {
        if (open) {
            loading()
            const projectAssignments = JSON.parse(localStorage.getItem("project-assignments"));
            setListProject(projectAssignments === null ? [] : projectAssignments);
            setStaffing(projectAssignments === null ? [] : projectAssignments.filter(item => item.isStaffing));
            setProjects(projectAssignments === null ? [] : projectAssignments.filter(item => !item.isStaffing));
            notLoading()
        }
    }, [open]);

    useEffect(() => {
        loading()
        CustomAxios.get(`time-entry/activities`, Config()).then(({ data }) => {
            setActivityList(data)
            notLoading()
        })
    }, [open]);

    useEffect(() => {
        loading()
        CustomAxios.get(`time-entry/assigned-project`, Config()).then(({ data }) => {
            setAssignedProject(data)
            notLoading()
        })
    }, [open]);

    useEffect(() => {
        if (projectId > 0) {
            loading()
            CustomAxios.get(`projects/${projectId}/issues`, Config()).then(({ data }) => {
                setIssueId(data[0].id)
                notLoading()
            })
        }

    }, [projectId])

    useEffect(() => {
        if (activityId > 0) {
            let
                selected = activityList.find(record => record.id === activityId),
                isFestive = selected && selected.description.toLowerCase().includes('festive'),
                isUnpaidLeave = selected && selected.description.toLowerCase().includes('unpaid leave');

            if (isFestive || isUnpaidLeave) {
                setValue('hours', 0);
                setHoursFieldVisible(false);
            }
            else {
                setHoursFieldVisible(true);
            }
        }
    }, [activityId, activityList, setValue, setHoursFieldVisible])


    const onClose = () => {
        handleClose()
        reset({
            projectId: "",
            activityId: "",
            assignedProjectId: "",
            spentOn: new Date(),
            hours: 0,
            comment: ""
        })
    }

    const onRegister = (value) => {
        loading()
        value["issueId"] = issueId
        value["spentOn"] = value["spentOn"].toISOString().substring(0, 10);

        if (!hoursFieldVisible)
            value["hours"] = 0;

        onClose()

        CustomAxios.post("time-entry", value, Config()).then(() => {
            notLoading()
            SuccessAlert(t("loadHours"), t("doneSuccessfully", {a: "a"})).then(() => reloadInitialMissingHour())
        }).catch(({ response: { data } }) => {
            notLoading()
            ErrorAlert(t("errorTitle"), data.message).then()
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit(onRegister)}>
                <DialogTitle>{t("loadHours")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <SelectProjectRegisterHourComponent control={control} defaultValue={""} label={t("project")}
                                                                    name="projectId"
                                                                    listValue={listProject} listStaffing={staffing} listProjects={projects} />
                                {errors.projectId &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.projectId.message}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <SelectActivityComponent control={control} defaultValue={""}
                                                         label={t("activities")}
                                                         name="activityId"
                                                         listValue={activityList}
                                />
                                {errors.activityId &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.activityId.message}</span>}
                            </FormControl>
                        </Grid>
                        {(existPermission("Estructura") || existPermission("Estructura Operativa")) && (<Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <SelectAssignedProjectComponent control={control} defaultValue={""}
                                                                    label={t("assignedProject")}
                                                                    name="assignedProjectId"
                                                                    listValue={assignedProjectList}
                                    />
                                    {errors.assignedProjectId &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.assignedProjectId.message}</span>}
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputDatePickerController
                                    control={control}
                                    defaultValue={new Date()}
                                    openTo='month'
                                    name="spentOn"
                                    label={t("date")}
                                    views={['year', 'month', 'day']}
                                />
                                {errors.spentOn &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.spentOn.message}</span>}
                            </FormControl>
                        </Grid>

                        {hoursFieldVisible &&
                            <Grid item xs={12} id="hours-field">
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputController
                                        control={control}
                                        defaultValue={0}
                                        label={t("hours")}
                                        name="hours"
                                    />
                                    {errors.hours &&
                                        <span style={{ fontSize: 12, color: "red" }}>{errors.hours.message}</span>}
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputController name="comment" control={control}
                                                 type="text"
                                                 defaultValue="" label={t('description')} />
                                {errors.comment &&
                                    <span style={{ fontSize: 12, color: "red" }}>{errors.comment.message}</span>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t("cancel")}</Button>
                    <Button type="submit">{t("load")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
