import React from "react";
import {createRoot} from 'react-dom/client';
import {HashRouter} from "react-router-dom";

import App from "./App";
import {MaterialUIControllerProvider} from "./context/index";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<HashRouter>
    <MaterialUIControllerProvider>
        <App/>
    </MaterialUIControllerProvider>
</HashRouter>);
