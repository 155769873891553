import {CacheProvider} from "@emotion/react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import React from "react";
import createCache from "@emotion/cache";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

const theme = createTheme({
    palette: { type: 'dark' },
    typography: { useNextVariants: true },
    shadows: Array(25).fill('none')
});

export const MuiTableComponent = ({options, columns, data, title}) => {
    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={theme}>
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </CacheProvider>
    );
}
