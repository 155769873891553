import { useEffect, useState } from "react";

import { Download, Cancel } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { MainLayout } from "../../../layout/MainLayout";
import MDBox from "../../../../components/MDBox";
import { ItemPreInvoiceComponent } from "./itemPreInvoiceComponent";
import { useLoading } from "../../../../hooks/useLoading";
import { MuiTableComponent, Translation, TranslationEN } from "../../../../components/MuiTable";
import { CustomAxios } from "../../../../custom/axiosCustom";
import { Config, ConfigHeaderBlobResponse, } from "../../../../utils/configHeader";
import { ErrorAlert } from "../../../../custom/SwalCustom";
import { getFormatDate } from "../../../../utils/getFormatDate";
import { CustomToolbarReloadComponent } from "../../../../components/MuiTable/CustomToolbarReloadComponent";

export const PreInvoiceView = () => {
  const { loading, thisLoading, notLoading } = useLoading();
  const [preInvoiceList, setPreInvoiceList] = useState([]);
  const [userData, setUserData] = useState();
  const {t, i18n: {language}} = useTranslation();

  const getListPreInvoice = () => {
    CustomAxios.get(`pre-invoices/pre-invoice-list`, Config())
      .then(async ({ data }) => {
        setPreInvoiceList(data);
      })
      .catch((error) => {
        ErrorAlert(t("errorTitle"), t("errorToGetPreInvoiceList")).then();
      })
      .finally(() => notLoading());
  };

  const reloadPreInvoice = () => {
    thisLoading()
    getListPreInvoice()
  }

  useEffect(() => {
    thisLoading()
    CustomAxios.get(`user-profile`, Config()).then(({ data }) => {
      notLoading()
      setUserData(data);
    }).catch((error) => {
      console.log(error)
    })
  }, []);

  const cancelPreInvoice = (preInvoiceId) => {
    const preInvoiceStatus = preInvoiceList.find(item => item.id === preInvoiceId);
    if (preInvoiceStatus.status !== 'CANCELLED') {
      Swal.fire({
        title: t("cancelledPreInvoiceTitle"),
        text: t("cancelledPreInvoiceQuestion"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("cancel"),
        cancelButtonText: t("exit"),
      }).then((result) => {
        if (result.isConfirmed) {
          CustomAxios.get(`pre-invoices/cancel-pre-invoice/${preInvoiceId}`, Config())
            .then(async ({ data }) => {
              Swal.fire({
                title: t("successfulCancellationTitle"),
                text: t("successfulCancellationMessage"),
                icon: 'success',
                confirmButtonText: 'OK'
              });
            })
            .catch((error) => {
              console.log("error => ", error);
              Swal.fire({
                title: t("cancelledPreInvoiceTitle"),
                text: t("errorCancelledPreInvoice"),
                icon: 'error',
                confirmButtonText: 'OK'
              });
            })
            .finally((info) => {
              getListPreInvoice();
            })
        }
      });
    } else {
      Swal.fire({
        title: t("cancelInvoice"),
        text: t("invoiceIsAlreadyCanceled"),
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const onDownload = (id) => {
    thisLoading();
    CustomAxios.get(
      `pre-invoices/${id}/download`,
      ConfigHeaderBlobResponse()
    )
      .then(({ data, headers }) => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = `${t("preInvoice")}_${userData.firstName}_${userData.lastName}${getFormatDate()}.pdf`;
        link.href = window.URL.createObjectURL(
          new Blob([data], {
            type: headers["content-type"],
          })
        );
        link.click();
        notLoading();
      })
      .catch(() => notLoading());
  };

  useEffect(() => {
    getListPreInvoice();
  }, []);

  const status = (value) => {
    switch (value) {
      case "CREATED":
        return <Chip label={t("created")} sx={{ backgroundColor: "#2962ff", color: "white" }} />
      case "SENT":
        return <Chip label={t("sent")} sx={{ backgroundColor: "#4bd86d", color: "white" }} />
      case "REJECTED":
        return <Chip label={t("rejected")} sx={{ backgroundColor: "#d47510", color: "white" }} />
      case "CANCELLED":
        return <Chip label={t("cancelled")} sx={{ backgroundColor: "#d42d10", color: "white" }} />
      case "REPLACED":
        return <Chip label={t("replaced")} sx={{ backgroundColor: "#f9a825", color: "white" }} />
      case "PAID":
        return <Chip label={t("paid")}sx={{ backgroundColor: "#9c27b0", color: "white" }} />
      case "POSTED":
        return <Chip label={t("posted")} sx={{ backgroundColor: "#0d47a1", color: "white" }} />
      case "ACCEPTED":
        return <Chip label={t("accepted")} sx={{ backgroundColor: "#4caf50", color: "white" }} />
    }
  }

  const columns = [
    {
      name: "createdAt",
      label: t("createdAt"),
    },
    {
      name: "details",
      label: t("client"),
      options: {
        customBodyRender: (value) => {
          return value.map(item => item.client) + " "
        }
      }
    },
    {
      name: "details",
      label: t("description"),
      options: {
        customBodyRender: (value) => {
          return value.map(item => item.description) + " "
        }
      }
    },
    {
      name: "details",
      label: t("modalityWork"),
      options: {
        customBodyRender: (value) => {
          return value.map(item => item.modalityWork) + " "
        }
      }
    },
    {
      name: "details",
      label: t("unitPrice"),
      options: {
        customBodyRender: (value) => {
          return value.map(item => item.priceUnit) + " "
        }
      }
    },
    {
      name: "hours",
      label: t("hours"),
    },
    {
      name: "month",
      label: t("month"),
    },
    {
      name: "year",
      label: t("year"),
    },

    {
      name: "benefitDiscount",
      label: t("discount"),
    },
    {
      name: "total",
      label: t("subTotal"),
    },
    {
      name: "currency",
      label: t("currency"),
    },
    {
      name: "status",
      label: t("status"),
      options: {
        customBodyRender: (value) => {
          return status(value)
        }
      }
    },
    {
      name: "id",
      label: t("download"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <IconButton onClick={() => { onDownload(value) }}>
              <Download />
            </IconButton>

          );
        }
      }
    },
    {
      name: "id",
      label: t("cancel"),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, {rowData}) => {
          if(rowData[11] !== "CREATED"){
            return ""
          }
          return (
            <IconButton
              onClick={() => {
                cancelPreInvoice(value);
              }}
            >
              <Cancel />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: true,
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    textLabels: language === 'es' ? Translation : TranslationEN,
    tableBodyHeight: "400px",
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
          <>
              <CustomToolbarReloadComponent
                  title={t("reloadData")}
                  onClickAction={reloadPreInvoice}
              />
          </>
      );
  }
  };

  return (
    <MainLayout loading={loading}>
      <MDBox py={3}>
        <ItemPreInvoiceComponent
          notLoading={notLoading}
          thisLoading={thisLoading}
          getListPreInvoice={getListPreInvoice}
          userData={userData}
        />
        <MuiTableComponent
          data={preInvoiceList}
          title={t("preInvoice")}
          options={options}
          columns={columns}
        />
      </MDBox>
    </MainLayout>
  );
};
