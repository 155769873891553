import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {Check, Upload} from "@mui/icons-material";

const UploadFile = ({file, onChangeFile, id, nameInput, textButton, fileLoaded}) => {
    return (
        <>
            <input
                accept=".pdf, .docx, .xlsx, .jpg, .jpeg, .png"
                style={{display: 'none',}}
                onChange={onChangeFile}
                id={id}
                name={nameInput}
                type="file"
            />
            <label htmlFor={id}>
                <Button
                    fullWidth
                    variant="contained"
                    component="span"
                    size="large"
                    style={{width: "100%", background: "#d7d5d5", color: "black"}}
                    startIcon={file === undefined || file === null ? <Upload/> : <Check/>}
                >
                    {file === undefined || file === null ? textButton : fileLoaded}
                </Button>
            </label>
        </>
    )
}

UploadFile.propTypes = {
    name: PropTypes.string,
    nameInput: PropTypes.string,
    label: PropTypes.string,
};

export default UploadFile
