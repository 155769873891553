import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import i18next from "i18next";

export const ReferredComponent = ({ getFile }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
         {i18next.t("corporatePrograms")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "20px" }}>
        <Box style={{ backgroundColor: "white" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align="center" variant="h3">
                {i18next.t("coderlandAmbassador")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={getFile("03_PP_referidos.png")}
                  style={{ width: "80%" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="h4">
                {i18next.t("programReferred")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
              <Container maxWidth="md">
                <Typography align="center" variant="p">
                  {i18next.t("benefitsCoderlandAmbassador")}
                  <b>
                   {i18next.t("secondBenefitsCoderlandAmbassador")}
                  </b>
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
              <Container maxWidth="md">
                <Button
                  component={Link}
                  target="_blank"
                  rel="noreferrer"
                  href="#/gestion-de-vacantes/vacantes"
                  underline="none"
                >
                  {i18next.t("vacanciesAvailable")}
                </Button>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
