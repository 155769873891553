import { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import { Cached, Download, Upload, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MDBox from "../../../../components/MDBox";
import MenuCard from "../../../../examples/Cards/MenuCards";
import { CustomAxios } from "../../../../custom/axiosCustom";
import {
  Config,
  ConfigHeaderBlobResponse,
} from "../../../../utils/configHeader";
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
} from "../../../../custom/SwalCustom";
import { DialogChargedInvoiceComponent } from "./dialogChargedInvoiceComponent";
import { usePermission } from "../../../../hooks/usePermission";
import { userContainPermission } from "../../../../utils/permission";
import { getFormatDate } from "../../../../utils/getFormatDate.js";

export const ItemInvoiceStandartComponent = ({ thisLoading, notLoading, getListInvoice, userData }) => {
  const { t } = useTranslation();
  const [dialogManual, setDialogManual] = useState(false);
  const [dialogAutomatic, setDialogAutomatic] = useState(false);
  const { permissionList } = usePermission("Tipo Factura");
  const [subTypes, setSubTypes] = useState("Horas")
  const [titleDialogManual, setTitleDialogManual] = useState(t("manualInvoiceUploadByHour"))

  const onOpenDialog = (type) => {
    setSubTypes(type);
    if (type === "Administrativa") {
      setTitleDialogManual(t("administrativeManualInvoiceUpload"))
    } else {
      setTitleDialogManual(t("manualInvoiceUploadByHour"))
    }
    setDialogManual(true);
  }
  const onCloseDialog = () => {
    setDialogManual(false);
  }
  const onOpenDialogAutomatic = () => setDialogAutomatic(true);
  const onCloseDialogAutomatic = () => setDialogAutomatic(false);

  const existPermission = (permission) => {
    return userContainPermission(permission, permissionList);
  };

  const onDownload = () => {
    thisLoading();
    // CustomAxios.get(`invoice-submissions`, Config())
    //     .then(({data}) => {
    //         CustomAxios.get(`invoice-submissions/${data[0].id}/download`, ConfigHeaderBlobResponse())
    //             .then(({data, headers}) => {
    //                 const link = document.createElement("a");
    //                 link.target = "_blank";
    //                 link.download = `factura${new Date().getTime()}.pdf`;
    //                 link.href = window.URL.createObjectURL(
    //                     new Blob([data], {
    //                         type: headers["content-type"],
    //                     })
    //                 );
    //                 link.click();
    //                 notLoading()
    //             }).catch(() => notLoading())
    //     }).catch(() => notLoading())
    CustomAxios.get(`invoices/download`, ConfigHeaderBlobResponse())
      .then(({ data, headers }) => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = `Factura_Inicial_${userData.firstName}_${userData.lastName}${getFormatDate()}.pdf`;
        link.href = window.URL.createObjectURL(
          new Blob([data], {
            type: headers["content-type"],
          })
        );
        link.click();
        notLoading();
      })
      .catch(({ response: { data } }) => {
        console.log(data);
        notLoading();
        InfoAlert(
          t("notFoundInvoice"),
          t("unableToDownloadInvoice")
        ).then();
      });
  };

  const onGenerate = () => {
    thisLoading();
    CustomAxios.get(`pre-invoices/pending`, Config())
      .then(({ data }) => {
        CustomAxios.get(`invoices/confirm-pre-invoice/${data[0].id}`, Config())
          .then(() => {
            notLoading();
            getListInvoice()
            SuccessAlert(t("invoiceGeneration"), t("doneSuccessfully", {a:"a"})).then();
          })
          .catch(({ response: { data } }) => {
            notLoading();
            if (data.code === 400) {
              ErrorAlert(t("errorToGenerateInvoiceTitle"), data.message).then();
              return;
            }
            ErrorAlert(t("errorTitle"), t("errorToGenerateInvoice")).then();
          });
      })
      .catch((response) => {
        notLoading();
        if (response.data === undefined) {
          InfoAlert(
            t("generateInvoice"),
            t("noPreInvoicesAvailableToGenerateInvoice")
          ).then();
          return;
        }
        if (response.data.code === 400) {
          ErrorAlert(t("errorToGenerateInvoiceTitle"), response.data.message).then();
          return;
        }
        ErrorAlert(t("errorTitle"), t("errorToGenerateInvoice")).then();
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <MDBox mb={1.5} onClick={onGenerate}>
          <MenuCard icon={Cached} title={t("generate")} />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={4} onClick={onDownload}>
        <MDBox mb={1.5}>
          <MenuCard icon={Download} title={t("downloadInvoiceGenerate")} />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <MDBox mb={1.5}>
          <Link to="/gestion-de-facturas/facturas/ver">
            <MenuCard icon={Visibility} title={t("invoicesSent")} />
          </Link>
        </MDBox>
      </Grid>
      {existPermission("Factura Personal") && (
        <Grid item xs={12} md={6} lg={4} xl={4} onClick={() => onOpenDialog("Horas")}>
          <MDBox mb={1.5}>
            <MenuCard icon={Upload} title={t("uploadManual")} />
          </MDBox>
        </Grid>
      )}
      {existPermission("Factura Personal Administrativa") && (
        <Grid item xs={12} md={6} lg={4} xl={4} onClick={() => onOpenDialog("Administrativa")}>
          <MDBox mb={1.5}>
            <MenuCard icon={Upload} title={t("administrativeInvoiceUpload")} />
          </MDBox>
        </Grid>
      )}

      <DialogChargedInvoiceComponent
        notLoading={notLoading}
        loading={thisLoading}
        handleClose={onCloseDialog}
        open={dialogManual}
        title={titleDialogManual}
        subTypes={subTypes}
        t={t}
      />
    </Grid>
  );
};
