import MDBox from "../../components/MDBox";
import {MainLayout} from "../layout/MainLayout";
import {ItemsComponent} from "./components/itemsComponent";

const Dashboard = () => {
    return (
        <MainLayout loading={false}>
            <MDBox py={3}>
                <ItemsComponent />
            </MDBox>
         </MainLayout>
    );
}

export default Dashboard;
