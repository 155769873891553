/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import CoverLayout from "../components/CoverLayout";

// Images
import bgImage from "../../../assets/images/bg-sign-up-cover.jpeg";
import Grid from "@mui/material/Grid";
import Dominion from "../../../assets/images/logos/logo.svg";
import {useState} from "react";

import axios from "axios";
import SnackbarComponent from "../../../components/Snackbar";
import {useForm, Controller} from "react-hook-form";

const Cover = () => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const navigate = useNavigate();
    const {handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            checkbox: false,
        }
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onRegister = (e) => {
        if (!e.email.includes('@dominion-global')) {
            setMessage("Error, debe ser un correo corporativo")
            setOpen(true)
        } else if(!/^[A-Za-z\s]*$/.test(e.name)){
            setMessage("Error, (Nombre) debe contener solo letras y espacio")
            setOpen(true)
        } else if(!/^[A-Za-z\s]*$/.test(e.surname)){
            setMessage("Error, (Apellido) debe contener solo letras y espacio")
            setOpen(true)
        }else {
            axios.post('http://localhost:8091/v1/auth', e)
                .then(function (response) {
                    if (response.status === 200) {
                        localStorage.setItem("user", JSON.stringify(response.data))
                        localStorage.setItem("token", JSON.stringify(response.data.token))
                        navigate("/inicio")
                    }
                })
                .catch(function (error) {
                    if(!!error.response.data.message){
                        setMessage(error.response.data.message);
                    }else{
                        setMessage("Ha ocurrido un error interno");
                    }
                    setOpen(true)
                });
        }
    }

    return (
        <CoverLayout image={bgImage}>
            <SnackbarComponent open={open} handleClose={handleClose} message={message}
                               alert="error"/>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <Grid container spacing={2} justifyContent="center" sx={{mt: 1, mb: 2}}>
                        <img src={Dominion}/>
                    </Grid>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Registro
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <form onSubmit={handleSubmit(onRegister)}>
                        <MDBox mb={2}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => <MDInput error={errors.name} type="text" label="Nombre"
                                                              variant="standard" fullWidth{...field} />}
                            />
                            {errors.name && errors.name.type === "required" &&
                            <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                        </MDBox>
                        <MDBox mb={2}>
                            <Controller
                                id="surname"
                                name="surname"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => <MDInput error={errors.surname} type="text" label="Apellido"
                                                              variant="standard" fullWidth{...field} />}
                            />
                            {errors.surname && errors.surname.type === "required" &&
                            <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                        </MDBox>
                        <MDBox mb={2}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => <MDInput error={errors.email} type="email" label="Correo"
                                                              variant="standard" fullWidth{...field} />}
                            />
                            {errors.email && errors.email.type === "required" &&
                            <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                        </MDBox>
                        <MDBox mb={2}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => <MDInput error={errors.password} type="password" label="Contraseña"
                                                              variant="standard" fullWidth{...field} />}
                            />
                            {errors.password && errors.password.type === "required" &&
                            <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                Registrar
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Ya tienes un cuenta?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Inicia Sesión
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </form>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default Cover;
