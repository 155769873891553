import { format, parseISO } from "date-fns";
import { IconButton, Chip } from "@mui/material";
import { Download, Visibility, Cancel, Upload } from "@mui/icons-material";
import Swal from "sweetalert2";
import i18next from "i18next";

import { CustomAxios, downloadFile, showFile } from "../../../custom/axiosCustom";
import { Config } from "../../../utils/configHeader";
import { ErrorAlert, ErrorServer, InfoAlert, SuccessAlert } from "../../../custom/SwalCustom";
import { types, subType, statusPreInvoice, statusForInvoice } from "../../management-invoice/component/CustomChipComponent";
import { Translation, TranslationEN } from "../../../components/MuiTable";
import { dollarUS } from "../../../utils/moneyFormat";
import { sendInvoiceAdminFunction } from "./SendInvoiceAdminFunction";

const ExecutorActions = (action, module, thisLoading, notLoading, redmineId) => {

    const onDownload = (id, consultantName, type, isInvoice = false) => {
        thisLoading()
        if (type === "PreFacturas") {
            downloadFile(
                `admin/pre-invoice/${redmineId}/${id}/download`,
                `PreFactura_${format(new Date(), 'dd_MM_yyyy_HH:mm:ss')}.pdf`
            ).finally(() => notLoading())
        }
        if (type === "Facturas") {
            const url = isInvoice ? `admin/invoice/${id}/download` : `admin/invoice-submission/${id}/download`
            downloadFile(
                url,
                `Factura_${consultantName}_${format(new Date(), 'dd_MM_yyyy_HH:mm:ss')}.pdf`
            ).finally(() => notLoading())

        }

    }

    const onView = (id, isInvoice) => {
        thisLoading()
        const url = isInvoice ? `admin/invoice/${id}/download` : `admin/invoice-submission/${id}/download`
        showFile(url)
            .catch(() => {
                ErrorServer().then()
            })
            .finally(() => notLoading())
    }

    const onSend = (invoiceId) => {
        sendInvoiceAdminFunction(thisLoading, notLoading, invoiceId, redmineId).then()
    }

    const columns = [
        {
            name: "id",
            options: {
                display: false
            }
        },
        {
            name: "consultantName",
            label:i18next.t("collaborator"),
        },
        {
            name: "invoiceNumber",
            label: i18next.t("reference"),
        },
        {
            name: "status",
            label: i18next.t("status"),
            options: {
                customBodyRender: (value, {rowData}) => {
                    return statusForInvoice(rowData[8] ? rowData[8].status : value, i18next.t)
                }
            }
        },
        {
            name: "types",
            label: i18next.t("type"),
            options: {
                customBodyRender: (value) => {
                    return types(value, i18next.t)
                }
            }
        },
        {
            name: "subTypes",
            label: i18next.t("subtype"),
            options: {
                customBodyRender: (value) => {
                    return subType(value, i18next.t)
                }
            }
        },
        {
            name: "rejectReason",
            label: i18next.t("rejectReason"),
        },
        {
            name: "total",
            label: "Total",
            options: {
                filter: true,
                customBodyRender: (value) => dollarUS.format(value),
            }
        },
        {
            name: "invoice",
            options: {
                display: false
            }
        },
        {
            name: "sentDate",
            label:  i18next.t("date"),
            options: {
                customBodyRender: (value, { rowData }) => {
                    return rowData[8] ? format(parseISO(rowData[8].createdAt), 'dd/MM/yyyy') : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "send",
            label:  i18next.t("send"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_value, { rowData }) => {
                    let isInvoice = !!rowData[8];
                    let invoiceId = isInvoice ? rowData[8].id :rowData[0]
                    if (!isInvoice) {
                        return ""
                    }
                    if (invoiceId && rowData[8].status !== 'CREATED') {
                        return ""
                    }

                    return (
                        <Chip
                            icon={<Upload style={{ color: 'white' }} />}
                            label={i18next.t("send")}
                            sx={{ backgroundColor: "#4bd86d", color: "white" }}
                            onClick={() => {
                                onSend(invoiceId);
                            }}
                        />
                    );
                },
            },
        },
        {
            name:  i18next.t("see"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_value, { rowData }) => {
                    let invoiceId = rowData[0]
                    let isInvoice = !!rowData[8];
                    if (isInvoice) {
                        console.log(rowData[8])
                        invoiceId = rowData[8].id
                    }
                    return (
                        <IconButton
                            onClick={() => onView(invoiceId, isInvoice)}>
                            <Visibility />
                        </IconButton>
                    );
                }
            }
        },
        {
            name: i18next.t("download"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_value, { rowData }) => {
                    let invoiceId = rowData[0]
                    let isInvoice = rowData[8] ? true : false;
                    if (isInvoice) {
                        console.log(rowData[8])
                        invoiceId = rowData[8].id
                    }
                    return (
                        <IconButton
                            onClick={() => onDownload(invoiceId, rowData[1], "Facturas", isInvoice)}>
                            <Download />
                        </IconButton>
                    );
                }
            }
        },
    ]

    const options = {
        search: true,
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: i18next.language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '400px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true
    }

    const columnsPreInvoice = [
        {
            name: "id",
            options: {
                display: false
            }
        },
        {
            name: "createdAt",
            label: i18next.t("createdAt"),
        },
        {
            name: "details",
            label: i18next.t("client"),
            options: {
                customBodyRender: (value) => {
                    return value.map(item => item.client) + " "
                }
            }
        },
        {
            name: "details",
            label:i18next.t("description"),
            options: {
                customBodyRender: (value) => {
                    return value.map(item => item.description) + " "
                }
            }
        },
        {
            name: "details",
            label: i18next.t("modalityWork"),
            options: {
                customBodyRender: (value) => {
                    return value.map(item => item.modalityWork) + " "
                }
            }
        },
        {
            name: "details",
            label: i18next.t("unitPrice"),
            options: {
                customBodyRender: (value) => {
                    return value.map(item => item.priceUnit) + " "
                }
            }
        },
        {
            name: "hours",
            label: i18next.t("hours"),
        },
        {
            name: "month",
            label: i18next.t("month"),
        },
        {
            name: "year",
            label: i18next.t("year"),
        },

        {
            name: "benefitDiscount",
            label: i18next.t("discount"),
        },
        {
            name: "total",
            label:i18next.t("subTotal"),
        },
        {
            name: "currency",
            label: i18next.t("currency"),
        },
        {
            name: "status",
            label:i18next.t("status"),
            options: {
                customBodyRender: (value) => {
                    return statusPreInvoice(value, i18next.t)
                }
            }
        },
        {
            name: "Descargar",
            label:i18next.t("download"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_value, { rowData }) => {
                    return (
                        <IconButton onClick={() => { onDownload(rowData[0], "", "PreFacturas") }}>
                            <Download />
                        </IconButton>

                    );
                }
            }
        },
        {
            name: "Cancelar",
            label: i18next.t("cancel"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (_value, { rowData }) => {
                    if(rowData[12] !== "CREATED"){
                        return ""
                    }

                    return (
                        <IconButton
                            onClick={() => {
                                cancelPreInvoice(rowData[0], rowData[12]);
                            }}
                        >
                            <Cancel />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const cancelPreInvoice = (preInvoiceId, status) => {
        if (status !== 'CANCELLED') {
            Swal.fire({
                title: i18next.t("cancelledPreInvoiceTitle"),
                text: i18next.t("cancelledPreInvoiceQuestion"),
                icon: "question",
                showCancelButton: true,
                confirmButtonText: i18next.t("cancel"),
                cancelButtonText: i18next.t("exit"),
            }).then((result) => {
                if (result.isConfirmed) {
                    thisLoading()
                    CustomAxios.get(`pre-invoices/cancel-pre-invoice/${preInvoiceId}`, Config())
                        .then(async ({ data }) => {
                            notLoading()
                            Swal.fire({
                                title: i18next.t("successfulCancellationTitle"),
                                text: i18next.t("successfulCancellationMessage"),
                                icon: 'success',
                                confirmButtonText: 'OK'
                            });
                        })
                        .catch((error) => {
                            console.log("error => ", error);
                            Swal.fire({
                                title: i18next.t("cancelledPreInvoiceTitle"),
                                text: i18next.t("errorCancelledPreInvoice"),
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                        })
                        .finally((info) => {
                           notLoading();
                        })
                }
            });
        } else {
            Swal.fire({
                title: i18next.t("cancelInvoice"),
                text: i18next.t("invoiceIsAlreadyCanceled"),
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleSuccess = ({ data }, columns, title) => {
        return { data: data, columns: columns, options: options, title: title };
    };

    const onGenerate = (redmineUserId) => {
        thisLoading();
        CustomAxios.get(`admin/pre-invoice/pending/${redmineUserId}`, Config())
            .then(({ data }) => {
                CustomAxios.get(`admin/pre-invoice/confirm/${redmineUserId}/${data[0].id}`, Config())
                    .then(() => {
                        notLoading();
                        SuccessAlert(i18next.t("invoiceGeneration"), i18next.t("doneSuccessfully", {a:"a"})).then();
                    })
                    .catch((response) => {
                        notLoading();
                        if (response.data?.code === 400) {
                            ErrorAlert(i18next.t("errorToGenerateInvoiceTitle"), data.message).then();
                            return;
                        }
                        ErrorAlert("Error", i18next.t("errorToGenerateInvoice")).then();
                    });
            })
            .catch((response) => {
                notLoading();
                if (response.data === undefined) {
                    InfoAlert(
                        i18next.t("generateInvoice"),
                        i18next.t("noPreInvoicesAvailable"),
                    ).then();
                    return;
                }
                if (response.data.code === 400) {
                    ErrorAlert(i18next.t("errorToGenerateInvoiceTitle"), response.data.message).then();
                    return;
                }
                ErrorAlert("Error", i18next.t("errorToGenerateInvoice")).then();
            });
    };

    const handleError = (error) => {
        ErrorAlert("Error", i18next.t("internalError")).then();
        throw error;
    };

    switch (module) {
        case "Facturas":
            switch (action) {
                case "Consultar":
                    return CustomAxios.get(`admin/invoice-submission/${redmineId}`, Config())
                        .then((response) => handleSuccess(response, columns,i18next.t("invoicesSent")))
                        .catch(handleError);
                case "Generar":
                    thisLoading()
                    onGenerate(redmineId)
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve("foo");
                        }, 0);
                    });
                case "Enviar":
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve("foo");
                        }, 0);
                    });
                default:
                    console.error(`Acción "${action}" no implementada en módulo "Facturas"`);
                    break;
            }
            break;
        case "PreFacturas":
            switch (action) {
                case "Consultar":
                    return CustomAxios.get(`admin/pre-invoice/${redmineId}`, Config())
                        .then((response) => handleSuccess(response, columnsPreInvoice, i18next.t("preInvoicesSent")))// Actualiza el estado directamente
                        .catch(handleError);
                case "Generar":
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve("foo");
                        }, 0);
                    });
                default:
                    console.error(`Acción "${action}" no implementada en módulo "PreFacturas"`);
                    break;
            }
            break;
        default:
            console.error(`Módulo "${module}" no encontrado`);
            break;
    }
};

export { ExecutorActions };