import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useLoading} from "../../../hooks/useLoading";
import {MainLayout} from "../../layout/MainLayout";

const Logout = () => {
    const navigate = useNavigate();
    const {thisLoading, notLoading, loading} = useLoading()
    useEffect(() => {
        thisLoading()
        localStorage.removeItem("user");
        localStorage.setItem("user", '')
        notLoading()
        navigate("/")
    }, [])

    return (
        <MainLayout loading={loading}/>
    );
}

export default Logout
