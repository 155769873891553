import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {InputLabel, MenuItem, Select, ListSubheader} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const SelectProjectRegisterHourComponent = ({control, name, label, rules, defaultValue, listValue, listStaffing, listProjects}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({field}) => (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                    <Select
                        {...field}
                        label={label}
                        defaultValue={defaultValue}
                        id="grouped-select"
                    >
                        {(listStaffing && listStaffing.length > 0) && <ListSubheader>Staffings</ListSubheader>}
                        {listStaffing?.map((value, index) => (
                                <MenuItem value={value.id} key={index}>
                                    {value.name}
                                </MenuItem>
                        ))}
                        {(listProjects && listProjects.length > 0) && <ListSubheader>Proyectos</ListSubheader>}
                        {listProjects?.map((value, index) => (
                                <MenuItem value={value.id} key={index}>
                                    {value.name}
                                </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    )
}

SelectProjectRegisterHourComponent.prototype = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
    control: PropTypes.element.isRequired,
    defaultValue: PropTypes.any.isRequired

}

export default SelectProjectRegisterHourComponent;
