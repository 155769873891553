import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { NumberFormatCustom } from "../../../../examples/NumberFormat";
import MDButton from "../../../../components/MDButton";
import { CustomAxios } from "../../../../custom/axiosCustom";
import { Config } from "../../../../utils/configHeader";
import { generateInvoiceFunction } from "./GenerateInvoiceFunction";

export const AddDetailComponent = ({
  thisLoading,
  notLoading,
  value,
  onDisabled,
  onActive,
  urlAddItem,
  urlPreInvoicePending,
  urlConfirmPreInvoice,
  t
}) => {
  const stateInitial = { description: "", units: 1, priceUnit: 0, total: 0 };
  const [inputFields, setInputFields] = useState([stateInitial]);
  const [errors, setErrors] = useState({ error: false, messageList: [] });

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    if (event.target.name === "units") {
      data[index]["total"] = event.target.value * data[index]["priceUnit"];
    }
    if (event.target.name === "priceUnit") {
      data[index]["total"] = data[index]["units"] * event.target.value;
    }

    setInputFields(data);
  };

  const addFields = () => {
    setInputFields([...inputFields, stateInitial]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
    if (validation(inputFields)) {
      notLoading();
      return;
    }
    thisLoading();
    onDisabled();
    const request = {
      preInvoiceDetails: inputFields,
    };
    CustomAxios.post(`${urlAddItem}`, request, Config()).then(
      ({ data }) => {
        setInputFields([stateInitial]);
          generateInvoiceFunction(data, thisLoading, notLoading, onActive, urlPreInvoicePending, urlConfirmPreInvoice);
      }
    );
  };

  const validation = (data) => {
    let messages = [];
    let error = false;
    data.map((element) => {
      if (element.description === "") {
        messages.push(t("descriptionNotEmpty"));
        error = true;
      }
      if (element.priceUnit === "") {
        messages.push(t("priceUnitsNotEmpty"));
        error = true;
      }
      if (element.total === "") {
        messages.push(t("totalNotEmpty"));
        error = true;
      }
    });
    setErrors({ error: error, messageList: messages });
    return error;
  };

  return (
    <>
      <Dialog open={value} maxWidth="xl" onClose={onDisabled}>
        <form onSubmit={submit}>
          <DialogTitle>{t("addAdditionalConcept")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {inputFields.map((input, index) => {
                  return (
                    <Box key={index} sx={{ mt: 1 }}>
                      <FormControl>
                        <TextField
                          required
                          value={input.description}
                          name="description"
                          id="component-outlined"
                          defaultValue="Composed TextField"
                          label={t("description")}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                      </FormControl>
                      <FormControl sx={{ ml: 2 }}>
                        <TextField
                          required
                          value={input.units}
                          name="units"
                          id="component-outlined"
                          defaultValue="1"
                          type="number"
                          label={t("units")}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                      </FormControl>
                      <FormControl sx={{ ml: 2 }}>
                        <TextField
                          required
                          value={input.priceUnit}
                          name="priceUnit"
                          id="component-outlined"
                          defaultValue="0"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          label={t("unitPrice")}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                      </FormControl>
                      <FormControl sx={{ ml: 2, mr: 1 }}>
                        <TextField
                          required
                          value={input.total}
                          name="total"
                          readonly
                          id="component-outlined"
                          defaultValue="0"
                          label={t("total")}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            readOnly: true,
                          }}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                      </FormControl>
                      <IconButton
                        color="error"
                        onClick={() => removeFields(index)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                {errors.error && (
                  <>
                    {" "}
                    <b style={{ color: "red" }}>Error: </b>
                    {errors.messageList.map((message) => (
                      <p style={{ fontSize: 12, color: "red" }}>{message}</p>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton variant="contained" color="success" onClick={addFields}>
             {t("addMore")}
            </MDButton>
            <MDButton onClick={submit} color="info">
              {t("register")}
            </MDButton>
            <MDButton onClick={onDisabled} color="error">
              {t("cancel")}
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
