import { Tabs, Tab, Typography, Link } from "@mui/material";
import {
  AccessTimeFilled,
  InsertDriveFile,
  PersonAdd,
  CorporateFare,
  Receipt,
  Topic,
  Phone,
  Article,
  ContentCut,
} from "@mui/icons-material";
import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import i18next from "i18next";
import { CustomAxios } from "../../../custom/axiosCustom";
import { Config } from "../../../utils/configHeader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const LinkUtilsComponent = () => {
  const [value, setValue] = useState(0);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    CustomAxios.get('cms/people-culture', Config())
    .then(({ data }) => {
      setLinks(data.links)
    })
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
      }}
    >
      <Typography variant="h4" align="center">
       {i18next.t("usefulLinks")}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box  sx={{ borderBottom: 1, borderColor: "divider", maxWidth: { xs: 320, sm: 480 }, }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="basic tabs example"
          >
            <Tab
              icon={<Phone />}
              label={i18next.t("stayConnected")}
              {...a11yProps(0)}
            />
            <Tab
              icon={<Article />}
              label={i18next.t("HRPolicies")}
              {...a11yProps(1)}
            />
            <Tab icon={<Article />} label={i18next.t("userManuals")} {...a11yProps(2)} />
            <Tab
              icon={<CorporateFare />}
              label={i18next.t("corporateResources")}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ul style={{ marginLeft: "10%" }}>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href="mailto:peopleculture@coderland.com"
                underline="none"
              >
                peopleculture@coderland.com
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href="mailto:administracion@coderland.com"
                underline="none"
              >
                administracion@coderland.com
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/coderland_by_dominion/?hl=es"
                underline="none"
              >
                Instagram
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/coderland-by-dominion/"
                underline="none"
              >
                Linkedin
              </Link>
            </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ul style={{marginLeft: "10%", width: "100%"}}>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_PTO']}
                  underline="none"
              >
                Family Day’s
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Coderland_Home']}
                  underline="none"
              >
                Coderland Home's
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Ingles']}
                  underline="none"
              >
                {i18next.t("en")}
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Seguro_Medico']}
                  underline="none"
              >
                {i18next.t("healthInsurance")}
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Udemy']}
                  underline="none"
              >
                Udemy
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Cheque_Aniversario']}
                  underline="none"
              >
               {i18next.t("anniversaryCheck")}
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Referidos']}
                  underline="none"
              >
                 {i18next.t("referralProgram")}
              </Link>
            </li>
            <li>
              <Link
                  target="_blank"
                  rel="noreferrer"
                  href={links['Link_Co_Workings']}
                  underline="none"
              >
                Co-Workings
              </Link>
            </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ul style={{marginLeft: "10%", width: "100%"}}>
            <li>
              <Link
                  href={links['Link_Instructivo_Redmine']}
                  target="_blank"
                  underline="none"
              >
                Redmine
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Redmine_Automatico']}
                underline="none"
              >
               {i18next.t("redmineAutomatic")}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Instructivo_Facturacion']}
                underline="none"
              >
                {i18next.t("billing")}
              </Link>
            </li>
            <li>
              {" "}
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Solicitudes']}
                underline="none"
              >
               {i18next.t("application")}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Trello']}
                underline="none"
              >
                Trello
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Portal']}
                underline="none"
              >
                Portal
              </Link>
            </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ul style={{ marginLeft: "10%", width: "100%" }}>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Manifiesto']}
                underline="none"
              >
               {i18next.t("cultureManifestoCapitalize").toLowerCase()}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Template_Coderland']}
                underline="none"
              >
                Template Coderland ppt
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Logos']}
                underline="none"
              >
                Logos
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noreferrer"
                href={links['Link_Modelo_Factura']}
                underline="none"
              >
               {i18next.t("invoiceTemplate")}
              </Link>
            </li>
          </ul>
        </TabPanel>
      </Box>
    </Box>
  );
};
