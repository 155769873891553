import {MainLayout} from "../../../layout/MainLayout";
import MDBox from "../../../../components/MDBox";
import {useLoading} from "../../../../hooks/useLoading";
import {Box, Card, Grid, Typography} from "@mui/material";
import {TableDetailsComponent} from "./TableDetailsComponent";
import {useEffect, useState} from "react";
import {CustomAxios} from "../../../../custom/axiosCustom";
import {Config} from "../../../../utils/configHeader";

export const VisualizerComponent = () => {
    const {loading, notLoading, thisLoading} = useLoading()
    const [data, setData] = useState()
    const [detailsInvoice, setDetailsInvoice] = useState([])
    useEffect(() => {
        thisLoading()
        CustomAxios.get("pre-invoices/current", Config()).then(({data})=>{
            console.log(data)
            setDetailsInvoice(data.details)
            notLoading()
        }).catch()
    }, []);

    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                <Card>
                    <MDBox p={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5">PREFACTURA</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6">Nombre: </Typography>
                                    <Typography variant="p" sx={{fontSize: 15}}>{" Jhon Beiker "}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6">Número: </Typography>
                                    <Typography variant="p" sx={{fontSize: 15}}>{" P00000005 "}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> Número de pasaporte o ID: </Typography>
                                    <Typography variant="p" sx={{fontSize: 15}}>{" P00000005 "}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> Fecha: </Typography>
                                    <Typography variant="p" sx={{fontSize: 15}}>{" 25/08/2022 "}</Typography>
                                </Box>
                            </Grid>
                           <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> País: </Typography>
                                    <Typography variant="p" sx={{fontSize: 15}}>{" Venezuela "}</Typography>
                                </Box>
                            </Grid>
                           <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="h6"> Ciudad: </Typography>
                                    <Typography variant="p" sx={{fontSize: 15}}>{" Barquisimeto "}</Typography>
                                </Box>
                            </Grid>



                            <Grid item xs={12}>
                                <TableDetailsComponent/>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            </MDBox>
        </MainLayout>
    )
}
