import Dashboard from "./layouts/dashboard";
import Profile from "./layouts/profile";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import LogoutComponent from "./layouts/authentication/logout";

// @mui icons
import {
    DashboardCustomize,
    Login,
    Person,
    Assignment,
    Logout,
    RequestQuote, Settings, AccessTimeFilled, PersonAdd
} from "@mui/icons-material";
import NotPermission from "./components/Error";
import PrivateRoute from "./PrivateRoute";
import ChargeHour from "./layouts/charge-hour";


import JobDetailComponent from "./layouts/management-vacant/vacant/component/JobDetailComponent";
import Vacancy from "./layouts/management-vacant/vacant/Vacancy";
import {CandidateSend} from "./layouts/management-vacant/candidate/CandidateSend";
import {ManagementRequestComponent} from "./layouts/management-request/ManagementRequestComponent";
import {RegisterRequestComponent} from "./layouts/management-request/component/RegisterRequestComponent";
import {ManagementHourView} from "./layouts/management-hour/ManagementHourView";
import ComfirmationInvoiceOutLoginComponent
    from "./layouts/management-invoice/component/ComfirmationInvoiceOutLoginComponent";
import {ManagementInvoiceView} from "./layouts/management-invoice/ManagementInvoiceView";
import {VacancyView} from "./layouts/management-vacant/VacancyView";
import {PreInvoiceView} from "./layouts/management-invoice/component/pre-invoice/PreInvoiceView";
import {VisualizerComponent} from "./layouts/management-invoice/component/pre-invoice/VisualizerComponent";
import {InvoiceView} from "./layouts/management-invoice/component/invoice/InvoiceView";
import {VisualizerInvoicesComponent} from "./layouts/management-invoice/component/invoice/VisualizerInvoicesComponent";
import {InvoiceDetailsComponent} from "./layouts/management-invoice/component/invoice/InvoiceDetailsComponent";
import {ReferredCommissionView} from "./layouts/referred-commission/ReferredCommissionView";
import { PeopleAndCulture } from "./layouts/page-info/peopleAndCulture";
import { BenefistCoderland } from "./layouts/page-info/benefist";
import { AdministrationCoderland } from "./layouts/page-info/administration";
import { FormationCoderland } from "./layouts/page-info/formation";
import AdminView from "./layouts/management-admin/AdminView";

const routes = [
    {
        type: "collapse",
        name: "people-and-culture",
        key: "people-and-culture",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/people-and-culture",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><PeopleAndCulture/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "formación",
        key: "formación",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/formacion",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><FormationCoderland/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "administración",
        key: "administracion",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/administracion",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><AdministrationCoderland/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "beneficios",
        key: "beneficios",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/beneficios",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><BenefistCoderland/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Inicio",
        key: "inicio",
        icon: <DashboardCustomize fontSize="small"/>,
        route: "/inicio",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><Dashboard/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "gestion-de-vacantes",
        key: "gestion-de-vacantes",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-vacantes",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><VacancyView/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "vacantes",
        key: "vacantes",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-vacantes/vacantes",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><Vacancy/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "view-job-offer",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-vacantes/vacantes/:id",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><JobDetailComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "view-job-offer",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-vacantes/candidatos",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><CandidateSend/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestion de Solicitud",
        key: "request",
        icon: <Settings fontSize="small"/>,
        route: "/solicitudes",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><ManagementRequestComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestion de Solicitud",
        key: "request",
        icon: <Settings fontSize="small"/>,
        route: "/solicitudes/registro",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><RegisterRequestComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Gestión de Horas",
        key: "gestion-de-horas",
        icon: <AccessTimeFilled fontSize="small"/>,
        route: "/gestion-de-horas",
        auth: true,
        component: <PrivateRoute><ManagementHourView/></PrivateRoute>,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "Referenciados",
        key: "referenciados",
        icon: <PersonAdd fontSize="small"/>,
        route: "/referenciados",
        auth: true,
        component: <PrivateRoute><ReferredCommissionView/></PrivateRoute>,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    //
    // {
    //     type: "collapse",
    //     name: "Gestión de Horas",
    //     key: "registro-descriptivo",
    //     icon: <AccessTimeFilled fontSize="small"/>,
    //     route: "/gestion-de-horas/registro-descriptivo",
    //     auth: true,
    //     component: <RegisterHourDescriptiveComponent/>,
    //     roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    // },
    //
    //
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "gestion-de-facturas",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><ManagementInvoiceView/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "facturas",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas/facturas",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><InvoiceView/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "prefactura",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas/prefactura",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><PreInvoiceView/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "prefactura",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas/prefactura/ver",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><VisualizerComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "ver",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas/facturas/ver",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><VisualizerInvoicesComponent/></PrivateRoute>,
    },
     {
        type: "collapse",
        name: "Ofertas De Empleos",
        key: "ver",
        icon: <Settings fontSize="small"/>,
        route: "/gestion-de-facturas/factura/ver/:invoiceSubmissionId",
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
        component: <PrivateRoute><InvoiceDetailsComponent/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Administración",
        key: "administracion",
        icon: <Settings fontSize="small"/>,
        route: "/panel-administrativo",
        auth: true,
        component: <PrivateRoute><AdminView/></PrivateRoute>,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL'],
    },
    {
        type: "collapse",
        name: "Profile",
        key: "perfil",
        icon: <Person fontSize="small"/>,
        route: "/perfil",
        auth: true,
        roles: ['ADMINISTRATOR', 'MANAGER', 'FINANCIAL_CONTROL', 'TELEFONICA', 'VISUALIZER'],
        component: <PrivateRoute><Profile/></PrivateRoute>,
    },
    {
        type: "collapse",
        name: "Sign In",
        key: "sign-in",
        icon: <Login fontSize="small"/>,
        route: "/",
        auth: false,
        roles: ['PUBLIC'],
        component: <SignIn/>,
    },
    {
        type: "collapse",
        name: "Sign Up",
        key: "sign-up",
        icon: <Assignment fontSize="small"/>,
        route: "/authentication/sign-up",
        auth: false,
        roles: ['ADMINISTRATOR'],
        component: <SignUp/>,
    },
    {
        type: "collapse",
        name: "Cerrar Sesión",
        key: "Cerrar Sesión",
        icon: <Logout fontSize="small"/>,
        route: "/logout",
        auth: false,
        roles: ['PUBLIC'],
        component: <LogoutComponent/>,
    },
    {
        type: "collapse",
        name: "Sin Permisos",
        key: "Sin Permisos",
        icon: <RequestQuote fontSize="small"/>,
        route: "/not-permission",
        auth: true,
        component: <NotPermission/>,
        roles: ['PUBLIC'],
    },
    {
        type: "collapse",
        name: "Charge Hour",
        key: "charge-hour",
        icon: <Login fontSize="small"/>,
        route: "/charge-hour/:id/:token/:projectName/:localDate/:type",
        auth: false,
        roles: ['PUBLIC'],
        component: <ChargeHour/>,
    },

    {
        type: "collapse",
        name: "Charge Hour",
        key: "charge-hour",
        icon: <Login fontSize="small"/>,
        route: "/comfimation/invoice/:token/:id",
        auth: false,
        roles: ['PUBLIC'],
        component: <ComfirmationInvoiceOutLoginComponent/>,
    },

];

export default routes;
