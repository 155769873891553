import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControl} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";

import MDBox from "../../../../components/MDBox";

const DialogChangePassword = ({openDialog, onRegister, handleClose}) => {
    const {handleSubmit, control, reset, watch, formState: {errors}} = useForm({mode: "onChange"});
    const password = watch("password")
    const repeatPassword = watch("repeatPassword")
    const [passwordEqual, setPasswordEqual] = useState(true)

    const resetValue = {
        password: "",
        repeatPassword: "",
    }

    useEffect(() => {
        if (password !== repeatPassword) {
            setPasswordEqual(false)
        }
        if (password === repeatPassword) {
            setPasswordEqual(true)
        }

    }, [password, repeatPassword])

    const onSubmit = (value) =>{
        onRegister(value)
        reset(resetValue)
    }

    const onCancel = () => {
        reset(resetValue)
        handleClose()
    }


    return (
        <>
            <Dialog open={openDialog} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Cambiar Contraseña</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <MDBox mt={3}>
                                    <Controller
                                        name="password"
                                        defaultValue=""
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: {
                                                value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                                                message: "name is invalid"
                                            }
                                        }}
                                        render={({field}) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    error={!passwordEqual}
                                                    label="Contraseña"
                                                    margin="dense"
                                                    value=""
                                                    type="password"
                                                    fullWidth
                                                    {...field}
                                                    name="password"
                                                    id="new_passord"
                                                    variant="standard"
                                                    helperText={!passwordEqual && "La contraseña no coincide"}
                                                />
                                                {errors.password && errors.password.type === "required" &&
                                                <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}
                                            </FormControl>
                                        )}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12}>
                                <MDBox mb={2}>
                                    <Controller
                                        name="repeatPassword"
                                        defaultValue=""
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: {
                                                value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                                                message: "name is invalid"
                                            }
                                        }}
                                        render={({field}) => (
                                            <FormControl fullWidth>
                                                <TextField
                                                    error={!passwordEqual}
                                                    label="Repite la contraseña"
                                                    margin="dense"
                                                    value=""
                                                    type="password"
                                                    fullWidth
                                                    {...field}
                                                    name="repeatPassword"
                                                    id="repeatPassword"
                                                    variant="standard"
                                                    helperText={
                                                        !passwordEqual && "La contraseña no coincide"

                                                    }
                                                />
                                                {errors.repeatPassword && errors.repeatPassword.type === "pattern" &&
                                                <span style={{fontSize: 12, color: "red"}}>La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, una minúscula y una mayúscula.</span>}
                                                {errors.repeatPassword && errors.repeatPassword.type === "required" &&
                                                <span style={{fontSize: 12, color: "red"}}>Es requerido</span>}

                                            </FormControl>
                                        )}
                                    />
                                </MDBox>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCancel}>Cancelar</Button>
                        <Button style={{backgroundColor: "#212121", color: "white"}} variant="contained"
                                type="submit">Cambiar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default DialogChangePassword;
