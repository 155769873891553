import { useEffect, useState } from "react";

import { Grid, FormControl, Card } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import i18next from "i18next";

import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import { MainLayout } from "../layout/MainLayout"
import { useLoading } from "../../hooks/useLoading"
import { SelectController, AutocompleteComponent } from "../../components/CustomInput";
import { ExecutorActions } from "./component/ExecutorActionsComponent";
import { MuiTableComponent } from "../../components/MuiTable";
import { CustomAxios } from "../../custom/axiosCustom";
import { Config } from "../../utils/configHeader";
import { PreInvoiceAdminComponent } from "./component/PreInvoiceAdminComponent";

const state = [
    { fullName: 'Sin Seleccionar', id: 1 }
]
const schema = yup.object().shape({

}).required();

const modules = [
    "PreFacturas",
    "Facturas"
]

const actionsAll = {
    Facturas: ["Generar", "Consultar"],
    PreFacturas: ["Generar", "Consultar"]
}

const AdminView = () => {
    const [collaborator, setCollaborator] = useState();
    const [action, setAction] = useState();
    const [collaboratorList, setCollaboratorList] = useState(state)
    const [info, setInfo] = useState(null);
    const { loading, thisLoading, notLoading } = useLoading()
    const [clickSubmit, setClickSubmit] = useState(false)
    
    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const moduleSelection = watch("module");
    const actionSelection = watch("action");

    const onChangeCollaborator = (value) => {
        setCollaborator(value);
    }

    useEffect(() => {
        Object.entries(actionsAll).map(([key, val]) => {
            if (key === moduleSelection) {
                setAction(val)
            }
        });
        setClickSubmit(false)
    }, [moduleSelection])

    useEffect(() => {
        setClickSubmit(false)
    }, [actionSelection])

    useEffect(() => {
        thisLoading()
        CustomAxios.get("admin/user/redmine", Config()).
            then(({ data }) => { setCollaboratorList(data) }).
            catch((error) => { console.log(error); }).
            finally(() => notLoading());
    }, [])

    const onExecute = (_value) => {
        setClickSubmit(false)
        thisLoading()
        ExecutorActions(actionSelection, moduleSelection, thisLoading, notLoading, collaborator.id).then(value => {
            setInfo(value)
            setClickSubmit(true)
        }).finally(() => {
            notLoading()
        })
    }

    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                <Card sx={{ height: "100%" }}>
                    <MDBox py={3} px={1}>
                        <form onSubmit={handleSubmit(onExecute)}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} md={4} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <Controller
                                            name="collaborator"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) =>
                                            (<AutocompleteComponent
                                                onChange={onChangeCollaborator}
                                                listValue={collaboratorList}
                                                valueSelected={collaborator}
                                                label={i18next.t("collaborator")}
                                                placeholder={i18next.t("collaborator")}
                                            />)
                                            }
                                        />
                                        {errors.candidate && errors.candidate.type === "required" &&
                                            <span style={{ fontSize: 12, color: "red" }}>Es requerido</span>}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={6} md={4} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <SelectController
                                            control={control}
                                            defaultValue=''
                                            label={i18next.t("module")}
                                            name="module"
                                            listValue={modules}
                                        />
                                        {errors.type && (
                                            <span style={{ fontSize: 12, color: "red" }}>
                                                {errors.type.message}
                                            </span>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={4} lg={4} xl={3}>
                                    <FormControl fullWidth disabled>
                                        <SelectController
                                            control={control}
                                            defaultValue={[]}
                                            label={i18next.t("action")}
                                            name="action"
                                            listValue={action}
                                        />
                                        {errors.type && (
                                            <span style={{ fontSize: 12, color: "red" }}>
                                                {errors.type.message}
                                            </span>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={4} lg={4} xl={3}>
                                    <MDButton
                                        variant="gradient"
                                        type="submit"
                                        color="info"
                                        fullWidth
                                    >
                                        {i18next.t("execute")}
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </form>
                        {actionSelection === "Consultar" && info !== null && clickSubmit && (<>
                            <br />
                            <MuiTableComponent data={info.data} title={info.title} columns={info.columns} options={info.options} />
                        </>)}
                        {actionSelection === "Generar" && moduleSelection === "PreFacturas" && clickSubmit && (
                            <PreInvoiceAdminComponent
                                notLoading={notLoading}
                                thisLoading={thisLoading}
                                userData={collaborator}
                            />
                        )}
                    </MDBox>
                </Card>
            </MDBox>
        </MainLayout>
    )
}

export default AdminView