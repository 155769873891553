import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Chip, Grid } from "@mui/material";
import { HealthAndSafety, MoneyOff, Pending, WorkOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { MainLayout } from "../layout/MainLayout";
import { useLoading } from "../../hooks/useLoading";
import { MuiTableComponent, Translation, TranslationEN } from "../../components/MuiTable";
import { CustomAxios } from "../../custom/axiosCustom";
import { Config } from "../../utils/configHeader";
import { ErrorServer } from "../../custom/SwalCustom";
import { CustomToolbarComponent } from "./component/CustomToolbarComponent";
import MDBox from "../../components/MDBox";
import MenuCard from "../../examples/Cards/MenuCards";
import { CustomToolbarReloadComponent } from "../../components/MuiTable/CustomToolbarReloadComponent";

const stateInitial = {
    daysAvailability: 0,
    daysAvailabilityPaid: 0,
    daysAvailabilityPending: 0,
    daysSicknessRecord: 0
}

export const ManagementRequestComponent = () => {
    const { t, i18n: {language} } = useTranslation();
    const { loading, thisLoading, notLoading } = useLoading()
    const [data, setData] = useState()
    const [dayAvalibilty, setDayAvalibity] = useState(stateInitial)
    const navigate = useNavigate()

    const reloadInitial = () => {
        thisLoading()
        CustomAxios.get("license-application/all", Config()).then(({ data }) => {
            setData(data)
            notLoading()
        }).catch(() => {
            ErrorServer().then()
        })
    }
    useEffect(() => {
        reloadInitial()
    }, [])

    useEffect(() => {
        thisLoading()
        CustomAxios.get("license-application/vacation-days-available", Config()).then(({ data }) => {
            setDayAvalibity(data)
            notLoading()
        }).catch()
    }, [])


    const onClickAction = () => {
        navigate("/solicitudes/registro")
    }

    const status = (value) => {
        switch (value) {
            case "Pendiente":
                return <Chip label={t("pending")} />
            case "Rechazado":
                return <Chip label={t("rejected")} sx={{ backgroundColor: "#d41010", color: "white" }} />
            case "Aprobado Supervisor":
                return <Chip label={t("preApproved")} sx={{ backgroundColor: "#05a724", color: "white" }} />
            case "Aprobado Gerencia":
                return <Chip label={t("approvedManagement")} sx={{ backgroundColor: "#056918", color: "white" }} />
            case "Reversar":
                return <Chip label={t("reverse")} sx={{ backgroundColor: "#fa7602", color: "white" }} />
            case "Cancelar":
                return <Chip label={t("cancelled")} />

        }
    }

    const columns = [
        {
            name: "supervisorEmail",
            label: t("supervisorEmail"),
        },
        {
            name: "type",
            label: t("type"),
            options: {
                customBodyRender: (value) => {
                    return t(value)
                }
            }
        },
        {
            name: "comments",
            label: t("observations"),
        },
        {
            name: "status",
            label: t("status"),
            options: {
                customBodyRender: (value) => {
                    return status(value)
                }
            }
        },
        {
            name: "startDate",
            label: t("startDate"),
        },
        {
            name: "endDate",
            label: t("endDate"),
        },
    ];

    const options = {
        search: true,
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '600px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
        customToolbar: () => {
            return (
                <>
                    <CustomToolbarReloadComponent
                        title={t("reloadData")}
                        onClickAction={reloadInitial}
                    />
                    <CustomToolbarComponent
                        title={t("registerApplication")}
                        onClickAction={onClickAction}
                        t={t}
                    />
                </>
            );
        }
    };

    return (
        <MainLayout loading={loading}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <MenuCard
                            icon={WorkOff}
                            title={t("daysAvailability") + ": " + dayAvalibilty.daysAvailability}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <MenuCard
                            icon={MoneyOff}
                            title={t("daysAvailabilityPaid") + ": " + dayAvalibilty.daysAvailabilityPaid}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <MenuCard
                            icon={Pending}
                            title={ t("daysAvailabilityPending") +": " + dayAvalibilty.daysAvailabilityPending}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                    <MDBox mb={1.5}>
                        <MenuCard
                            icon={HealthAndSafety}
                            title={t("daysSicknessRecord") +": " + dayAvalibilty.daysSicknessRecord}
                        />
                    </MDBox>
                </Grid>
            </Grid>
            <MuiTableComponent columns={columns} data={data}
                title={t("application")}
                options={options} />
        </MainLayout>

    )
}
