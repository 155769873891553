import { useEffect, useState } from "react";

import { format, parseISO } from "date-fns";
import { Chip, IconButton } from "@mui/material";
import { Cancel, Check, Download, Visibility, CancelScheduleSend, CoPresent, BrowserUpdated, QueryBuilder, ReceiptLong } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { CustomAxios, downloadFile, showFile } from "../../../../custom/axiosCustom";
import { MainLayout } from "../../../layout/MainLayout";
import MDBox from "../../../../components/MDBox";
import { useLoading } from "../../../../hooks/useLoading";
import { useUserProfile } from "../../../../hooks/useUserProfile";
import { MuiTableComponent, Translation, TranslationEN } from "../../../../components/MuiTable";
import { Config } from "../../../../utils/configHeader";
import { CustomToolbarReloadComponent } from "../../../../components/MuiTable/CustomToolbarReloadComponent";
import { ErrorServer } from "../../../../custom/SwalCustom";
import { dollarUS } from "../../../../utils/moneyFormat";
import { subType, types, status } from "../CustomChipComponent";

export const VisualizerInvoicesComponent = () => {
    const { loading, thisLoading, notLoading } = useLoading()
    const { t, i18n: {language} } = useTranslation();
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const { userData } = useUserProfile();

    const getInvoiceSubmissions = () => {
        thisLoading()
        CustomAxios.get("invoice-submissions", Config()).then(({ data }) => {
            setData(data)
            notLoading()
        }).catch(() => {
            notLoading()
            ErrorServer().then()
        })
    }

    useEffect(() => {
        getInvoiceSubmissions();
    }, []);


    const onView = (index) => {
        if (data[index].invoice) {
            navigate(`/gestion-de-facturas/factura/ver/${data[index].id}`)
        } else {
            thisLoading()
            const url = `invoice-submissions/${data[index].id}/download`
            showFile(url)
                .catch(() => {
                    ErrorServer().then()
                })
                .finally(() => notLoading())
        }
    }

    const onDownload = (index) => {
        thisLoading()
        downloadFile(
            `invoice-submissions/${data[index].id}/download`,
            `Factura_Inicial_${userData.firstName}_${userData.lastName}_${format(new Date(), 'dd_MM_yyyy_HH:mm:ss')}.pdf`
        ).finally(() => notLoading())
    }

    const onCancelInvoice = (index) => {
        const invoiceSelected = data[index];
        if (invoiceSelected.status === 'Cancelada') {
            Swal.fire({
                title: 'Cancelar factura',
                text: 'Esta factura ya se encuentra cancelada',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else if (invoiceSelected.status === 'Aprobada') {
            Swal.fire({
                title: 'Cancelar factura',
                text: 'Esta factura ya se encuentra aprobada',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {
            Swal.fire({
                title: "Cancelar factura",
                text: "¿Deseas cancelar esta factura?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Cancelar",
                cancelButtonText: "Salir",
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('Cancel invoice');
                    CustomAxios.get(`invoice-submissions/cancel-invoice-submission/${invoiceSelected.id}`, Config())
                        .then(async ({ data }) => {
                            Swal.fire({
                                title: 'Cancelación exitosa',
                                text: 'La factura se ha cancelado exitosamente',
                                icon: 'success',
                                confirmButtonText: 'OK'
                            });
                        })
                        .catch((error) => {
                            console.log("error => ", error);
                            Swal.fire({
                                title: 'Cancelar factura',
                                text: 'Error al cancelar factura',
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                        })
                        .finally((info) => {
                            getInvoiceSubmissions();
                        })
                }
            });
        }
    };

    const columns = [
        {
            name: "invoiceNumber",
            label:t("reference"),
        },
        {
            name: "status",
            label: t("status"),
            options: {
                customBodyRender: (value) => {
                    return status(value, t)
                }
            }
        },
        {
            name: "types",
            label: t("type"),
            options: {
                customBodyRender: (value) => {
                    return types(value, t)
                }
            }
        },
        {
            name: "subTypes",
            label: t("subtype"),
            options: {
                customBodyRender: (value) => {
                    return subType(value, t)
                }
            }
        },
        {
            name: "rejectReason",
            label: t("rejectReason"),
        },
        {
            name: "sentDate",
            label: t("sentInvoiceDate"),
            options: {
                customBodyRender: (value) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "total",
            label: t("total"),
            options: {
                filter: true,
                customBodyRender: (value) => dollarUS.format(value),
            }
        },
        {
            name: t("see"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => onView(dataIndex)}>
                            <Visibility />
                        </IconButton>
                    );
                }
            }
        },
        {
            name: t("download"),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => onDownload(dataIndex)}>
                            <Download />
                        </IconButton>
                    );
                }
            }
        },
        /*{
            name: "Cancelar",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => onCancelInvoice(dataIndex)}>
                            <Cancel/>
                        </IconButton>
                    );
                }
            }
        },*/
    ]

    const options = {
        search: true,
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '400px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
        customToolbar: () => {
            return (
                <>
                    <CustomToolbarReloadComponent
                        title={t("reloadData")}
                        onClickAction={getInvoiceSubmissions}
                    />
                </>
            );
        }
    };

    return (
        <MainLayout loading={loading}>
            <MDBox py={3}>
                <MuiTableComponent data={data} title="Facturas Enviadas" options={options} columns={columns} />
            </MDBox>
        </MainLayout>
    )
}
