import PropTypes from "prop-types";

import MDBox from "../../../components/MDBox";
import {Card, Grid, Typography} from "@mui/material";

function MenuCard({color, title, icon}) {
    const Icons = icon;
    return (
        <Card pt={1} px={2}>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        {/*<MDBox display="flex" justifyContent="space-between" pt={1} px={2}>*/}
                        <MDBox
                            variant="gradient"
                            bgColor={color}
                            color={color === "light" ? "dark" : "white"}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="4rem"
                            height="4rem"
                        >
                            <Icons fontSize="medium" color="inherit"/>
                        </MDBox>
                    </Grid>
                    <Grid item xs={9}>
                        <MDBox textAlign="right" height="4rem" mt={1.2}>
                            <Typography style={{overflow: "hidden", textOverflow: "ellipsis"}} noWrap={false}
                                        variant="h5" fontWeight="light">
                                {title}
                            </Typography>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of ComplexStatisticsCard
MenuCard.defaultProps = {
    color: "info",
    percentage: {
        color: "success",
        text: "",
        label: "",
    },
};

// Typechecking props for the ComplexStatisticsCard
MenuCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
};

export default MenuCard;
