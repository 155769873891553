import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {TextField} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const InputController = ({control, name, label, rules, type, defaultValue}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({field}) => (
                <FormControl fullWidth>
                    <TextField
                        label={label}
                        margin="dense"
                        value=""
                        fullWidth
                        type={type}
                        {...field}
                        name={name}
                        id="formatted-numberformat-input"
                        variant="outlined"
                    />
                </FormControl>
            )}
        />
    )
}

InputController.prototype = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
    control: PropTypes.element.isRequired,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.any.isRequired

}

export default InputController;
