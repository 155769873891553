import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const AutocompleteComponent = ({listValue, valueSelected, onChange, label, placeholder}) => {
    const options = listValue.map((value) => {
        const firstLetter = value.fullName[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...value,
        };
    });

    return (
        <Autocomplete
            fullWidth
            value={valueSelected}
            id="grouped-demo"
            onChange={(_event, newTeam) => {
                onChange(newTeam);
            }}
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.fullName}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
    );
}
