import React, {useState} from 'react';

import format from "date-fns/format";
import {Box, Button, Card, CardContent, CardHeader, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

import DialogSupportComponent from "../../../../examples/Navbars/DashboardNavbar/component/dialogSupportComponent";
import {
    contentSupportVacancy,
    titleSupportVacancy
} from "../../../../examples/Navbars/DashboardNavbar/component/messageDialog";
import {Config} from "../../../../utils/configHeader";
import {CustomAxios} from "../../../../custom/axiosCustom";
import {DialogPostulateCandidateComponent} from "./DialogPostulateCandidateComponent";

export default function CardJobsComponent({vacancy, thisLoading, notLoading}) {
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState(false)
    const [message, setMessage] = useState('')
    const [vacancyId, setVacancyId] = useState()
    const [openDialogPostulate, setOpenDialogPostulate] = useState(false)

    const onClickOpenDialog = () => setOpenDialog(true);
    const onClickCloseDialog = () => setOpenDialog(false);
    const onChangeMessage = (e) => setMessage(e.target.value)

    const onClickOpenDialogPostulate = (id) => {
        setVacancyId(id)
        setOpenDialogPostulate(true);
    }
    const onClickCloseDialogPostulate = () => setOpenDialogPostulate(false);

    const onSendMessage = () => {
        onClickCloseDialog()
        CustomAxios.post(`vacancies/${vacancy.id}/send-message/`, {message}, Config()).then(() => {
            Swal.fire(
                t("messageSent"),
                t("successfully"),
                'success'
            ).then()
        }).catch((error) => {
        })
    }

    const getPriority = (priority) => {
        switch (priority) {
            case "1 (Alta)":
                return t("high")
            case "2 (Media)":
                return t("half")
            case"3 (Baja)":
                return t("low")
        }
    }

    return (
        <Card style={{marginBottom: 20}}>
            <CardHeader title={vacancy.name}/>
            <CardContent>
                <Typography paragraph style={{fontSize: "15px"}}>
                    {vacancy.description}
                </Typography>
                {/*<Typography paragraph style={{fontSize: "15px"}}>*/}
                {/*    <b>Rate máximo: </b>{dollarUS.format(vacancy.rateMaxUSD)}*/}
                {/*</Typography>*/}
                {/*<Typography paragraph style={{fontSize: "15px"}}>*/}
                {/*    <b>Rate mínimo: </b>{dollarUS.format(vacancy.rateMinUSD)}*/}
                {/*</Typography>*/}

                <Typography paragraph style={{fontSize: "15px"}} display="flex">
                    <b>{t("openingDate")}: </b><p style={{color: "white"}}></p><p
                    style={{color: 'red'}}>{" " + format(new Date(vacancy.createdAt), "dd-MM-yyyy")}</p>
                </Typography>
                <Typography paragraph style={{fontSize: "15px"}}>
                    <b>{t("priority")}: </b>{getPriority(vacancy.priority)}
                </Typography>
                <Box display='flex' justifyContent='end'>
                    <Button variant="contained" style={{backgroundColor: "black", color: "white", marginRight: 10}}
                            onClick={onClickOpenDialog}>{t("vacancyInquiry")}</Button>
                    <Link to={"/gestion-de-vacantes/vacantes/" + vacancy.id}>
                        <Button variant="contained" style={{backgroundColor: "black", color: "white", marginRight: 10}}>{t("moreDetails")}</Button>
                    </Link>
                    <Button variant="contained" style={{backgroundColor: "black", color: "white"}}
                            onClick={()=>{onClickOpenDialogPostulate(vacancy.id)}}>{t("nominateCandidate")}</Button>
                </Box>
                <DialogSupportComponent
                    open={openDialog}
                    title={titleSupportVacancy}
                    content={contentSupportVacancy}
                    onClickClose={onClickCloseDialog}
                    message={message}
                    onChangeMessage={onChangeMessage}
                    onSend={onSendMessage}
                />
                <DialogPostulateCandidateComponent
                    handleClose={onClickCloseDialogPostulate}
                    open={openDialogPostulate}
                    vacancyId={vacancyId}
                    notLoading={notLoading}
                    loading={thisLoading}
                    t={t}
                />
            </CardContent>
        </Card>
    );
}
