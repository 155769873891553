import {format, parseISO} from "date-fns";
import {MuiTableComponent, Translation, TranslationEN} from "../../../components/MuiTable";
import {dollarUS} from "../../../utils/moneyFormat";

export const ReferredCommissionSummaryComponent = ({notLoading, thisLoading, data, t, language}) => {

    const columns = [
        {
            name: "createdAt",
            label: t("date"),
            options: {
                customBodyRender: (value) => {
                    return value === undefined ? '' : format(parseISO(value), 'dd/MM/yyyy')
                }
            }
        },
        {
            name: "candidateName",
            label: t("name"),
        },
        {
            name: "candidateLastname",
            label: t("lastname"),
        },
        {
            name: "candidateEmail",
            label: t("mail"),
        },
        {
            name: "vacancyName",
            label: t("vacancy"),
        },
        {
            name: "amount",
            label: t("commissionAmount"),
            options: {
                customBodyRender: (value) => {
                    return value === undefined ? '' : dollarUS.format(value);
                }
            }
        },
    ]

    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: false,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels:  language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '400px',
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
    };

    return (
        <MuiTableComponent data={data} title={t("referenced")} options={options} columns={columns}/>
    )
}
