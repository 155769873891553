import {Button, Tooltip} from "@mui/material";
import { Add } from "@mui/icons-material";

export const CustomToolbarComponent = ({ title, onClickAction, t }) => {
  return (
    <>
      <Tooltip title={title}>
        <Button variant="outlined" onClick={onClickAction} startIcon={<Add />}>
          {t("register")}
        </Button>
      </Tooltip>
    </>
  );
};
