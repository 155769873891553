import * as React from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";
import Image from '../../assets/images/no-television.png'
import MDBox from "../MDBox";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MDButton from "../MDButton";

const NotPermission = () => {
    const navigate = useNavigate()
    return (
        <PageLayout>
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={4}>
                        <Card>
                            <MDBox
                                borderRadius="lg"
                                coloredShadow="grey"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <Grid container justifyContent="center" sx={{mt: 3, mb: 2}}>
                                    <img alt="image" width='100px' src={Image}/>
                                </Grid>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <MDBox mb={2}>
                                        <Typography textAlign='center' variant="h1" gutterBottom component="div">
                                            403
                                        </Typography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Typography textAlign='center' variant="h3" gutterBottom component="div">
                                            Acceso No Autorizado
                                        </Typography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Typography textAlign='center' variant="h5" gutterBottom component="div">
                                            Su usuario no cuenta con los permisos necesarios para acceder a este módulo, para más información puede contactar a los administradores del sistema.
                                        </Typography>
                                    </MDBox>

                                    <MDBox mt={4} mb={1}>
                                        <MDButton onClick={onHome} color="black" fullWidth>
                                            Volver Al Inicio
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

        </PageLayout>
    );
}

export default NotPermission;
