let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    roundingIncrement: 5
});

let euroSpanish = Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
    roundingIncrement: 5
});

const FormatNumberMoney = (currency) => {
    let locale = "en-US"
    if (currency === "EUR"){
        locale = "es-ES"
    }
    return Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
        roundingIncrement: 5
    });
}

export {
    dollarUS, euroSpanish, FormatNumberMoney
}
