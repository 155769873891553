import {MuiTableComponent, Translation, TranslationEN} from "../../../../components/MuiTable";
import {TableFooterDetailsInvoiceComponent} from "../invoice/tableFooterDetailsInvoiceComponent";
import {dollarUS} from "../../../../utils/moneyFormat";

export const TableDetailsComponent = ({details, t, language}) => {

    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        responsive: "vertical",
        textLabels: language === 'es' ? Translation : TranslationEN,
        tableBodyHeight: '100%',
        shadow: false,
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
        pagination: false,
        sort: false,
        customTableBodyFooterRender: (opt) => {
            return (
                <TableFooterDetailsInvoiceComponent values={opt}/>
            )
        }
    };

    const columns = [
        {
            name: "reference",
            label:t("reference"),

        },
        {
            name: "description",
            label: t("article"),

        },
        {
            name: "units",
            label: t("units"),

        },
        {
            name: "unitPrice",
            label: t("unitPrice"),
            options: {
                filter: true,
                customBodyRender: (value) => dollarUS.format(value),
            }
        },
        {
            name: "subtotal",
            label: t("subTotal"),
            options: {
                filter: true,
                customBodyRender: (value) => dollarUS.format(value),
            }
        },
        {
            name: "iva",
            label: "% IVA",
        },
        {
            name: "total",
            label: t("totalWithIVA"),
            options: {
                filter: true,
                customBodyRender: (value) => dollarUS.format(value),
            }
        },
    ]


    return (
        <MuiTableComponent data={details} title={""} options={options} columns={columns}/>
    );
}
